<template>
  <div class="home">
    <el-button @click="openDialog">打开</el-button>
    <el-dialog v-model="dialogVisible" title="Tips" width="30%">
      <div>
        <FloorIframe></FloorIframe>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="dialogVisible = false">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  Picture as ElIconPictureOutline,
  BellFilled as ElIconMessageSolid,
  CircleClose as ElIconCircleClose
} from '@element-plus/icons-vue'
import { shallowRef } from 'vue'
import Vue3Barcode from 'vue3-barcode'
import vueEsign from 'vue-esign'
import CustomHtmlInput from '@/components/commonUse/CustomHtmlInput.vue'
import jsCamera from '@/components/photo/jsCamera.vue'
import FloorIframe from "./threeD/floorIframe.vue";

export default {
  name: 'Home',
  components: {
    jsCamera,
    vueEsign,
    Vue3Barcode,
    CustomHtmlInput,
    ElIconPictureOutline,
    ElIconMessageSolid,
    ElIconCircleClose,
    FloorIframe
  },
  data() {
    return {
      operateVal: null,
      textarea1: '<span>Test</span>',
      dialogVisible: false,
      flag: false,
      show: true,
      value: '',
      fileList: [],
      options: [
        { label: "Option A", value: "a" },
        { label: "Option B", value: "b" },
        { label: "Option B2", value: "b" },
      ],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          phone: 12345678910,
          address: '上海市普陀区金沙江路 1518 弄',
          describe: '松江区，位于上海市西南部，历史文化悠久，有着“上海之根” 的称呼。位于黄浦江上游，东与闵行区、奉贤区为邻，南、西南与金山区交界，西、北与青浦区接壤'

        }
      ]
    }
  },
  mounted() {
    // setTimeout(this.bindSelectScroll, 3000)
    // this.bindSelectScroll()
    // this.handleScroll()
  },
  methods: {
    cameraChange() {
    },
    isNeedSectioncount() {
    },
    openDialog() {
      this.dialogVisible = true
      // setTimeout(this.resize, 1500)

    },
    resize() {
      console.log('resize')
      this.$refs.vueEsignTest.$_resizeHandler()
    },
    cal() {
      alert('123')
    },
    changeList() {
      this.tableData[1].date = '111-11-11'
      this.$forceUpdate()
    },
    changeFN() {
      return 'asdgh'
    },
    selectScroll() {
      console.log('SelectScroll')
    },
    bindSelectScroll() {
      let selectElement = el.querySelector('.el-select-dropdown')
      console.log(selectElement)
      selectElement.addEventListener('scroll', function () {
        const condition = this.scrollHeight - this.scrollTop <= this.clientHeight

        if (condition) {
          this.loadMore()
        }
      })
    },
    loadMore() {
      // 分页加载数据的逻辑
      console.log('触底加载更多...')
    },
    handleScroll() {
      console.log(this.$refs.scrollbar)
      let scrollbarEl = this.$refs.scrollbar.wrapperKls[0]
      scrollbarEl.onscroll = () => {
        if (scrollbarEl.scrollTop > 135) {
          this.loadMore()
        } else {
          this.loadMore()
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/css/font.css';

.fm-word {
  font-size: 16px;
  font-weight: 550;
  //font-family: PMZD;
  font-family: DIN;
  font-style: normal;
}

.imgDiv {
  width: 570px;
  height: 170px;
}

.allmap {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}

:deep(.el-input) {
  position: relative;
  font-size: 14px;
  width: 100%;
}

.divBlock {
  //display: flex;
  //flex-wrap: wrap;
  //align-items: center;
  //flex: 1;
  //line-height: 32px;
  //position: relative;
  //font-size: var(--font-size);
  //min-width: 0;

  position: relative;
  font-size: 14px;
}
</style>
