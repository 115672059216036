import test from "../views/test.vue";
import { markRaw } from "vue";

export const routerData = [
    {
        path: '/test',
        name: 'test',
        component: test
    },
    {
        path: '/',
        name: 'loginRoot',
        // component: () => import('@/views/pgLogin'),
        component: () => import('@/views/login')
    },
    {
        path: '/login',
        name: 'login',
        // component: () => import('@/views/pgLogin'),
        component: () => import('@/views/login')
    },
    {
        path: '/ssoLogin',
        name: 'ssoLogin',
        component: () => import('@/views/ssoLogin/ssoLogin')
    },
    //首页
    {
        path: '/jczxHomePage',
        name: 'jczxHomePage',
        component: () =>
            import('@/views/aloneViews/jczx/jczxHomePage/jczxHomePage'),
        meta: { title: '首页', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/jczxNotice',
        name: 'jczxNotice',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxNotice') //通知公告
    },
    {
        path: '/jczxNoticeDitails',
        name: 'jczxNoticeDitails',
        component: () =>
            import('@/views/aloneViews/jczx/jczxHomePage/jczxNoticeDitails') //通知公告
    },
    //   {
    //     path: '/jczxWorkStatus',
    //     name: 'jczxWorkStatus',
    //     component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxWorkStatus') //工作动态
    //   },
    {
        path: '/jczxWork',
        name: 'jczxWork',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxWork') //工作动态
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/aboutUs.vue') //联系我们
    },
    {
        path: '/HomePage',
        name: 'HomePage',
        component: () => import('@/views/HomePage/index')
    },
    {
        path: '/inputFingerprint',
        name: 'inputFingerprint',
        component: () => import('@/views/inputFingerprint')
    },
    {
        path: '/flipBook',
        name: 'flipBook',
        component: () => import('@/views/threeD/threeD-train/flipBook-train')
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: markRaw(() => import('@/views/home')),
        children: [
            {
                path: '/redirect',
                component: () => import('@/views/redirect/index'),
                hidden: true,
                children: [
                    {
                        path: '/redirect/:path(.*)'
                    }
                ]
            },
            {
                path: 'dragHome',
                name: 'dragHome',
                component: () => import('@/views/dragHome.vue'),
                meta: { title: '推拽组件', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'floor',
                name: 'floor',
                component: () => import('@/views/threeD/floor'),
                meta: { title: '3D楼层', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'floorIframe',
                name: 'floorIframe',
                component: () => import('@/views/threeD/floorIframe'),
                meta: {
                    title: '3D楼层平面版',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/dashboardhome'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'ssoIframe', //单点登录
                name: 'ssoIframe',
                component: () => import('@/views/ssoLogin/ssoIframe')
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'perExamine',
                name: 'perExamine',
                component: () => import('@/views/singlePage/perExamine'),
                meta: { title: '注册审核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: {
                    title: '任务管理平台',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'userIndex',
                name: 'UserIndex',
                component: () => import('@/views/UserIndex'),
                meta: { title: '人员信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userDetail',
                name: 'UserDetail',
                component: () => import('@/views/UserDetail'),
                meta: { title: '人员详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'management',
                name: 'Management',
                component: () => import('@/views/FileManagement/management'),
                meta: {
                    title: '管理架构维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'postMaintenance',
                name: 'PostMaintenance',
                component: () => import('@/views/FileManagement/postMaintenance'),
                meta: {
                    title: '岗位档案维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // {
            //   path: 'withdrawal',
            //   name: 'Withdrawal',
            //   component: ()=>import('@/views/Withdrawal/withdrawal') ,
            //   meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'Withdrawalorder',
                name: 'Withdrawalorder',
                component: () => import('@/views/Withdrawal/Withdrawalorder'),
                meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'withdrawalDetails',
            //   name: 'withdrawalDetails',
            //   component: ()=>import('@/views/Withdrawal/withdrawalDetails') ,
            //   meta: { title: '商品明细', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'withdrawalOrderDetails',
                name: 'withdrawalOrderDetails',
                component: () => import('@/views/Withdrawal/withdrawalOrderDetails'),
                meta: {
                    title: '门店退仓商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            // {
            //   path: 'scancode',
            //   name: 'scancode',
            //   component: ()=>import('@/views/Withdrawal/scancode') ,
            //   meta: { title: '扫码', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'scancodeOrder',
                name: 'scancodeOrder',
                component: () => import('@/views/Withdrawal/scancodeOrder'),
                meta: {
                    title: '门店退仓扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersIn',
                name: 'transfersIn',
                component: () => import('@/views/Transfers/transfersIn'),
                meta: {
                    title: '门店调拨-转入',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersInDetail',
                name: 'transfersInDetail',
                component: () => import('@/views/Transfers/transfersInDetail'),
                meta: {
                    title: '门店调拨转入明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'transfersOut',
                name: 'transfersOut',
                component: () => import('@/views/Transfers/transfersIndex'),
                meta: {
                    title: '门店调拨-转出',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersOutDetail',
                name: 'transfersOutDetail',
                component: () => import('@/views/Transfers/transfersDetail'),
                meta: {
                    title: '门店调拨转出明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancode',
                name: 'tranScancode',
                component: () => import('@/views/Transfers/scancode'),
                meta: {
                    title: '门店转出扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancodeIn',
                name: 'tranScancodeIn',
                component: () => import('@/views/Transfers/scancodeIn'),
                meta: {
                    title: '门店转入扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'receiving',
                name: 'receiving',
                component: () => import('@/views/Receiving/receiving'),
                meta: { title: '收货复核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receivingDetails',
                name: 'receivingDetails',
                component: () => import('@/views/Receiving/receivingDetails'),
                meta: {
                    title: '收货复核商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'recevescancode',
                name: 'recevescancode',
                component: () => import('@/views/Receiving/recevescancode'),
                meta: {
                    title: '收货复核扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'OutboundReturnReport',
                name: 'OutboundReturnReport',
                component: () => import('@/views/storeReport/OutboundReturnReport'),
                meta: {
                    title: '门店出库退货查询',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: {
                    title: '配置报表展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: {
                    title: '配置表单展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: {
                    title: '配置表单分类展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'InvoicingReport',
                name: 'InvoicingReport',
                component: () => import('@/views/storeReport/InvoicingReport'),
                meta: {
                    title: '仓店任意日进销存报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'posindex',
                name: 'posindex',
                component: () => import('@/views/Pos/posindex'),
                meta: { title: '在线POS', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlan',
                name: 'StoreInventoryPlan',
                component: () => import('@/views/StoreInventory/StoreInventoryPlan'),
                meta: {
                    title: '门店盘点计划',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryPlanDetail',
                name: 'StoreInventoryPlanDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryPlanDetail'),
                meta: {
                    title: '门店盘点计划明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryList',
                name: 'StoreInventoryList',
                component: () => import('@/views/StoreInventory/StoreInventoryList'),
                meta: { title: '门店盘点单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryListDetail',
                name: 'StoreInventoryListDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryListDetail'),
                meta: {
                    title: '门店盘点单详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreAdjustmentList',
                name: 'StoreAdjustmentList',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentList'),
                meta: { title: '门店调整单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentDetail',
                name: 'StoreAdjustmentDetail',
                component: () =>
                    import('@/views/StoreAdjustment/StoreAdjustmentDetail'),
                meta: {
                    title: '门店调整单明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'carouselScreen/:id',
                name: 'carouselScreen',
                component: () => import('@/views/show/carouselScreen'),
                meta: {
                    title: '大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangePromotion',
                name: 'priceChangePromotion',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotion'),
                meta: { title: '变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotionDetail',
                name: 'priceChangePromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotionDetail'),
                meta: {
                    title: '编辑变价促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeBatchModify',
                name: 'priceChangeBatchModify',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/batchModify'),
                meta: {
                    title: '变价促销批量修改',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionPromotion',
                name: 'mixPromotionPromotion',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotion'),
                meta: { title: '组合促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotionDetail',
                name: 'mixPromotionPromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotionDetail'),
                meta: {
                    title: '编辑组合促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'promotionInstructions',
                name: 'promotionInstructions',
                component: () =>
                    import('@/views/PromotionManagement/promotionInstructions'),
                meta: {
                    title: '促销说明管理',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamSetting',
                name: 'priceChangeParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSetting'),
                meta: {
                    title: '变价参数配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamQuickSetting',
                name: 'priceChangeParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramQuickSetting'),
                meta: {
                    title: '变价参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamDetail',
                name: 'priceChangeParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSettingDetail'),
                meta: {
                    title: '变价参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeListSetting',
                name: 'priceChangeListSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/listSetting'),
                meta: {
                    title: '变价列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamSetting',
                name: 'mixPromotionParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSetting'),
                meta: {
                    title: '组合促销参数设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamQuickSetting',
                name: 'mixPromotionParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramQuickSetting'),
                meta: {
                    title: '组合参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamDetail',
                name: 'mixPromotionParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSettingDetail'),
                meta: {
                    title: '组合参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionListSetting',
                name: 'mixPromotionListSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/listSetting'),
                meta: {
                    title: '组合列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myReports',
                name: 'myReports',
                component: () => import('@/views/configureReports/myLike'),
                meta: { title: '我的收藏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'table',
                name: 'table',
                component: () => import('@/views/configurationForm/table'),
                meta: {
                    title: '配置表单静态用table',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/configurationForm/form'),
                meta: {
                    title: '配置表单静态用form',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formtab',
                name: 'formtab',
                component: () => import('@/views/configurationForm/tabs'),
                meta: {
                    title: '配置表单静态用tab',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: {
                    title: '接口数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: {
                    title: '接口数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: {
                    title: '抛送数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: {
                    title: '抛送数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: {
                    title: '接口对外文档',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'processControl',
            //   name: 'processControl',
            //   component: () => import('@/views/processDesign/processControl/index'),
            //   meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            // },

            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () =>
                    import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessIndex'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessMain.vue'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () =>
                    import('@/views/processDesign/processManage/myProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: {
                    title: '打印模板设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: {
                    title: '打印模板设置新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: {
                    title: '打印模板列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: {
                    title: '打印模板列表新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'developNeeds',
                name: 'developNeeds',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '开发需求', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onlinePlan',
                name: 'onlinePlan',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '上线计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('@/views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false } //首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('@/views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('@/views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('@/views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('@/views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'informFile',
                name: 'informFile',
                component: () => import('@/views/Inform/tab'),
                meta: { title: '通知文件', icon: 'el-icon-user-solid', affix: false }
            },
            // 瑞克斯新增路由
            {
                path: 'waybillList',
                name: 'waybillList',
                component: () => import('@/views/dispatch/waybillList/list'),
                meta: { title: '运单管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waybillConfirm',
                name: 'waybillConfirm',
                component: () =>
                    import('@/views/dispatch/waybillConfirm/waybillConfirm'),
                meta: { title: '运单确认', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'distributionPeportGoods',
                name: 'distributionPeportGoods',
                component: () =>
                    import('@/views/dispatch/distributionPeportGoods/list'),
                meta: { title: '配货报货', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupDispatchList',
                name: 'pickupDispatchList',
                component: () => import('@/views/dispatch/pickupDispatchList/list'),
                meta: { title: '提货派车', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupHandover',
                name: 'pickupHandover',
                component: () => import('@/views/dispatch/pickupHandover/list'),
                meta: { title: '提货交接', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onwayList',
                name: 'onwayList',
                component: () => import('@/views/onway/onwayList/list'),
                meta: { title: '在途管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'travelMap',
                name: 'travelMap',
                component: () => import('@/views/onway/travel/map'),
                meta: { title: '轨迹追踪', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'stowageTransfer',
                name: 'stowageTransfer',
                component: () => import('@/views/onway/stowageTransfer/list'),
                meta: { title: '配载中转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'deliverySignin',
                name: 'deliverySignin',
                component: () => import('@/views/onway/deliverySignin/list'),
                meta: { title: '派送签收', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'abnormalList',
                name: 'abnormalList',
                component: () => import('@/views/onway/abnormalList/list'),
                meta: { title: '异常管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPermission',
                name: 'userPermission',
                component: () => import('@/views/userPermission/list'),
                meta: {
                    title: '用户权限配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'KPITimelinessRate',
                name: 'KPITimelinessRate',
                component: () => import('@/views/tmsDialog/KPITimelinessRate'),
                meta: { title: '到货及时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIOnTimeRate',
                name: 'KPIOnTimeRate',
                component: () => import('@/views/tmsDialog/KPIOnTimeRate'),
                meta: { title: '提货准时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIEfficiency',
                name: 'KPIEfficiency',
                component: () => import('@/views/tmsDialog/KPIEfficiency'),
                meta: { title: '提货效率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SCMKremainProcess',
                name: 'SCMKremainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/SCMKremainProcess/tab'),
                meta: {
                    title: '生产模块-待办任务',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/taskAgents/addProcessIndex'
                    ),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'sampleDetail',
            //   name: 'sampleDetail',
            //   component: () => import('@/views/sampleDetail/index'),
            //   meta: { title: '戎美样衣详情', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'jumpurl',
                name: 'jumpurl',
                component: () => import('@/views/jumpUrl'),
                meta: { title: '跳转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpTo/:menuid',
                name: 'jumpTo',
                component: () => import('@/components/commonData/jumpTo'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAReport',
                name: 'BAReport',
                component: () => import('@/views/BAReport/index'),
                meta: { title: '项目信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAFundReport',
                name: 'BAFundReport',
                component: () => import('@/views/BAReport/BAFundReport'),
                meta: {
                    title: '项目资金报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            //配置大屏、BI
            {
                path: 'Settingscreen',
                name: 'Settingscreen',
                component: () => import('@/views/setting/settingScreen'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingScreenN',
                name: 'settingScreenN',
                component: () => import('@/views/screenSettingNew/tab'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toCsetting',
                name: 'toCsetting',
                component: () => import('@/views/setting/toCPlussetting.vue'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //     path: 'toCsetting',
            //     name: 'toCsetting',
            //     component: toCSetting,
            //     meta: {title: '大屏配置', icon: 'el-icon-user-solid', affix: false},
            // },
            {
                path: 'toBIsetting',
                name: 'toBIsetting',
                component: () => import('@/views/setting/toBIsetting'),
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReport',
                name: 'SettingBIReport',
                component: () => import('@/views/BIConfiguration/index'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReportN',
                name: 'SettingBIReportN',
                component: () => import('@/views/BIConfiguration/indexNew'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'show/:pageId',
                name: 'showScreen',
                component: () => import('@/views/show/showScreen'),
                meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'chooseSwiperScreen',
                name: 'chooseSwiperScreen',
                component: () => import('@/views/setting/chooseSwiperScreen'),
                meta: {
                    title: '设置大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'carouselScreenList',
                name: 'carouselScreenList',
                component: () => import('@/views/show/carouselScreenList'),
                meta: {
                    title: '大屏组合轮播列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            //交警项目
            // {
            //   path: 'facilityChange/:menuid',
            //   name: 'facilityChange',
            //   component: () => import('@/views/singlePage/facilityChange'),
            //   meta: { title: '设施/标线变更', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'facilityList',
            //   name: 'facilityList',
            //   component: () => import('@/views/singlePage/facilityChange/facilityList'),
            //   meta: { title: '设施列表', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('@/views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'demo',
            //   name: 'demo',
            //   component: ()=>import('@/views/demo'),
            //   meta: { title: '地图首页', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'roadLine',
            //   name: 'roadLine',
            //   component: ()=>import('@/views/DMap/roadLine'),
            //   meta: { title: '道路标线', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'areaLine',
            //   name: 'areaLine',
            //   component: ()=>import('@/views/DMap/areaLine'),
            //   meta: { title: '区域维护', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'faclity',
            //   name: 'faclity',
            //   component: ()=>import('@/views/faclity'),
            //   meta: { title: '套组详情', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'doubleMapCompare',
            //   name: 'doubleMapCompare',
            //   component: () => import('@/components/dmap/doubleScreenCompare'),
            //   meta: { title: '双屏对比', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'View_line_change_history',
            //   name: 'View_line_change_history',
            //   component: () => import('@/views/View_line_change_history'),
            //   meta: { title: '标线变更历史查看', icon: 'el-icon-user-solid', affix: false }
            // },
            //  {
            //   path: 'customReport',
            //   name: 'customReport',
            //   component: () => import('@/views/custom_report'),
            //   meta: { title: '定制报表', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'customStyle',
            //   name: 'customStyle',
            //   component: () => import('@/views/customStyle'),
            //   meta: { title: '片区分布', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'mapTotalData',
            //   name: 'mapTotalData',
            //   component: () => import('@/views/DMap/mapTotalData'),
            //   meta: { title: '大屏', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'DMap',
            //   name: 'DMap',
            //   component: () => import('@/views/DMap/index'),
            //   meta: { title: '地图', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'mapCompare',
            //   name: 'mapCompare',
            //   component: () => import('@/views/DMap/mapCompare'),
            //   meta: { title: '地图对比', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('@/views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },

            // {
            //   path: 'getSignal',
            //   name: 'getSignal',
            //   component: () => import('@/views/DMap/getSignal'),
            //   meta: { title: '信号机坐标转换', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: '2DReport',
            //   name: '2DReport',
            //   component: () => import('@/views/2DReportInfo/groupIndex'),
            //   meta: { title: '设施总览', icon: 'el-icon-user-solid', affix: false }
            // },
            //树型菜单
            {
                path: 'treeMenu',
                name: 'treeMenu',
                //component: () => import('@/views/login')
                component: resolve =>
                    require(['@/views/configurationForm/treeMenu'], resolve)
            },
            //调度中心页面
            {
                path: 'dispatchingCenter',
                name: 'dispatchingCenter',
                component: () =>
                    import('@/views/aloneViews/pg/dispatchingCenter/index'),
                meta: { title: '调度中心', icon: 'el-icon-user-solid', affix: false }
            },
            // //部门->驻点 跳转到排班页面
            // {
            //     path: 'chooseJump',
            //     name: 'chooseJump',
            //     component: () => import('@/views/aloneViews/pg/chooseJump/chooseJump'),
            //     meta: {title: '部门->驻点', icon: 'el-icon-user-solid', affix: false}
            // },

            // {
            //     path: 'arrangeGrade',
            //     name: 'arrangeGrade',
            //     component: () => import('@/views/aloneViews/pg/scheduling/arrangeGrade'),
            //     // component: arrangeGrade,
            //     meta: {title: '排班', icon: 'el-icon-user-solid', affix: false}
            // },
            // //个人排班
            // {
            //     path: 'perScheduling',
            //     name: 'perScheduling',
            //     component: () => import('@/views/aloneViews/pg/scheduling/perScheduling'),
            //     meta: {title: '个人排班', icon: 'el-icon-user-solid', affix: false}
            // },
            //人员画像
            {
                path: 'perPortrait',
                name: 'perPortrait',
                component: () => import('@/views/aloneViews/pg/perPortrait/index'),
                meta: { title: '人员画像', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dialogSearchJL',
                name: 'dialogSearchJL',
                component: () => import('@/views/aloneViews/jczx/dialogSearchJL'),
                meta: { title: '弹窗', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'appointmentIndex',
                name: 'appointmentIndex',
                component: () => import('@/views/aloneViews/jczx/appointmentIndex'),
                meta: { title: '计量所首页', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'qualityInspectionInstitute',
                name: 'qualityInspectionInstitute',
                component: () =>
                    import('@/views/aloneViews/jczx/qualityInspectionInstitute'),
                meta: { title: '质检所首页', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'inputFingerprint',
                name: 'inputFingerprint',
                component: () => import('@/views/inputFingerprint'),
                meta: { title: '录入指纹', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            }
        ]
    },
    {
        path: '/show/:pageId',
        name: 'bigshowScreen',
        component: () => import('@/views/show/showScreen'),
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bsettingScreenN',
        name: 'bsettingScreenN',
        component: () => import('@/views/screenSettingNew/tab'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showBI/:pageId',
        name: 'bigshowBI',
        component: () => import('@/views/BIConfiguration/showBI'), //BI配置
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('@/views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('@/views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bprocess',
        name: 'bprocess',
        component: () =>
            import('@/views/processDesign/processControl/bigprocessmodify'),
        meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/echartspage',
        name: 'echartspage',
        component: () => import('@/views/EchartsPage/echartspage'),
        meta: { title: 'echarts', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/caseguide',
        name: 'caseguide',
        component: () => import('@/views/PropertyManagesystem/caseGuide'),
        meta: {
            title: '常熟市涉案财物管理中心',
            icon: 'el-icon-user-solid',
            affix: false
        }
    },
    {
        path: '/uploadFace',
        name: 'uploadFace',
        component: () => import('@/views/customMade/uploadFace'),
        meta: { title: '人脸上传', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/BIConfiguration',
        name: 'BIConfiguration',
        component: () => import('@/views/BIConfiguration/BIConfiguration'),
        meta: { title: 'BI参数配置', icon: 'el-icon-user-solid', affix: false }
    },

    //戎美售后
    {
        path: '/receiptVoucher',
        name: 'receiptVoucher',
        component: () => import('@/views/RMAfterSaleSys/receiptVoucher'),
        meta: { title: '入库制单', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/ExpressUnpacking',
        name: 'ExpressUnpacking',
        component: () => import('@/views/RMAfterSaleSys/ExpressUnpacking'),
        meta: { title: '快递拆包', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/OutorderSearch',
        name: 'OutorderSearch',
        component: () => import('@/views/RMAfterSaleSys/OutorderSearch'),
        meta: { title: '出库单查询', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/AddOut',
        name: 'AddOut',
        component: () => import('@/views/RMAfterSaleSys/AddOut'),
        meta: { title: '出库单新增', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Refund',
        name: 'Refund',
        component: () => import('@/views/RMAfterSaleSys/Refund'),
        meta: { title: '退款', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Maintenance',
        name: 'Maintenance',
        component: () => import('@/views/RMAfterSaleSys/Maintenance'),
        meta: { title: '维修单', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/QualityTest',
        name: 'QualityTest',
        component: () => import('@/views/RMAfterSaleSys/QualityTest'),
        meta: { title: '质检', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Anomaly',
        name: 'Anomaly',
        component: () => import('@/views/RMAfterSaleSys/Anomaly'),
        meta: { title: '异常处理', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/RefundAnomaly',
        name: 'RefundAnomaly',
        component: () => import('@/views/RMAfterSaleSys/RefundAnomaly'),
        meta: { title: '退款异常处理', icon: 'el-icon-user-solid', affix: false }
    }
]

export const routerDataRM = [
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test.vue')
    },
    {
        path: '/',
        name: 'loginIndex',
        component: () => import('@/views/login')
    },
    {
        path: '/login',
        name: 'login',
        // component: () => import('@/views/login')
        component: () => import('@/views/login')
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: () => import('@/views/home'),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'newLive',
                name: 'newLive',
                component: () => import('@/views/showScreen/newLive'),
                meta: { title: '直播大屏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'tableScreenLogin',
                name: 'tableScreenLogin',
                component: () => import('@/views/showScreen/tableScreenLogin'),
                meta: { title: '直播登录', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'tableScreen',
                name: 'tableScreen',
                component: () => import('@/views/showScreen/tableScreen'),
                meta: { title: '戎美定制大屏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'perExamine',
                name: 'perExamine',
                component: () => import('@/views/singlePage/perExamine'),
                meta: { title: '注册审核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: {
                    title: '任务管理平台',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'userIndex',
                name: 'UserIndex',
                component: () => import('@/views/UserIndex'),
                meta: { title: '人员信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userDetail',
                name: 'UserDetail',
                component: () => import('@/views/UserDetail'),
                meta: { title: '人员详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'management',
                name: 'Management',
                component: () => import('@/views/FileManagement/management'),
                meta: {
                    title: '管理架构维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'postMaintenance',
                name: 'PostMaintenance',
                component: () => import('@/views/FileManagement/postMaintenance'),
                meta: {
                    title: '岗位档案维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'Withdrawalorder',
                name: 'Withdrawalorder',
                component: () => import('@/views/Withdrawal/Withdrawalorder'),
                meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'withdrawalOrderDetails',
                name: 'withdrawalOrderDetails',
                component: () => import('@/views/Withdrawal/withdrawalOrderDetails'),
                meta: {
                    title: '门店退仓商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'scancodeOrder',
                name: 'scancodeOrder',
                component: () => import('@/views/Withdrawal/scancodeOrder'),
                meta: {
                    title: '门店退仓扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersIn',
                name: 'transfersIn',
                component: () => import('@/views/Transfers/transfersIn'),
                meta: {
                    title: '门店调拨-转入',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersInDetail',
                name: 'transfersInDetail',
                component: () => import('@/views/Transfers/transfersInDetail'),
                meta: {
                    title: '门店调拨转入明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'transfersOut',
                name: 'transfersOut',
                component: () => import('@/views/Transfers/transfersIndex'),
                meta: {
                    title: '门店调拨-转出',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersOutDetail',
                name: 'transfersOutDetail',
                component: () => import('@/views/Transfers/transfersDetail'),
                meta: {
                    title: '门店调拨转出明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'generatePicture',
                name: 'generatePicture',
                component: () => import('@/views/generatePicture/generatePicture'),
                meta: {
                    title: '图片生成工具',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancode',
                name: 'tranScancode',
                component: () => import('@/views/Transfers/scancode'),
                meta: {
                    title: '门店转出扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancodeIn',
                name: 'tranScancodeIn',
                component: () => import('@/views/Transfers/scancodeIn'),
                meta: {
                    title: '门店转入扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'receiving',
                name: 'receiving',
                component: () => import('@/views/Receiving/receiving'),
                meta: { title: '收货复核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receivingDetails',
                name: 'receivingDetails',
                component: () => import('@/views/Receiving/receivingDetails'),
                meta: {
                    title: '收货复核商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'recevescancode',
                name: 'recevescancode',
                component: () => import('@/views/Receiving/recevescancode'),
                meta: {
                    title: '收货复核扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'OutboundReturnReport',
                name: 'OutboundReturnReport',
                component: () => import('@/views/storeReport/OutboundReturnReport'),
                meta: {
                    title: '门店出库退货查询',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: {
                    title: '配置报表展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: {
                    title: '配置表单展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: {
                    title: '配置表单分类展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'InvoicingReport',
                name: 'InvoicingReport',
                component: () => import('@/views/storeReport/InvoicingReport'),
                meta: {
                    title: '仓店任意日进销存报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'posindex',
                name: 'posindex',
                component: () => import('@/views/Pos/posindex'),
                meta: { title: '在线POS', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlan',
                name: 'StoreInventoryPlan',
                component: () => import('@/views/StoreInventory/StoreInventoryPlan'),
                meta: {
                    title: '门店盘点计划',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryPlanDetail',
                name: 'StoreInventoryPlanDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryPlanDetail'),
                meta: {
                    title: '门店盘点计划明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryList',
                name: 'StoreInventoryList',
                component: () => import('@/views/StoreInventory/StoreInventoryList'),
                meta: { title: '门店盘点单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryListDetail',
                name: 'StoreInventoryListDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryListDetail'),
                meta: {
                    title: '门店盘点单详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreAdjustmentList',
                name: 'StoreAdjustmentList',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentList'),
                meta: { title: '门店调整单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentDetail',
                name: 'StoreAdjustmentDetail',
                component: () =>
                    import('@/views/StoreAdjustment/StoreAdjustmentDetail'),
                meta: {
                    title: '门店调整单明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },

            // {
            //   path:'carouselScreen/:id',
            //   name:'carouselScreen',
            //   component:()=>import('@/views/show/carouselScreen') ,
            //   meta: { title: '大屏组合轮播', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'priceChangePromotion',
                name: 'priceChangePromotion',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotion'),
                meta: { title: '变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotionDetail',
                name: 'priceChangePromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotionDetail'),
                meta: {
                    title: '编辑变价促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeBatchModify',
                name: 'priceChangeBatchModify',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/batchModify'),
                meta: {
                    title: '变价促销批量修改',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionPromotion',
                name: 'mixPromotionPromotion',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotion'),
                meta: { title: '组合促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotionDetail',
                name: 'mixPromotionPromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotionDetail'),
                meta: {
                    title: '编辑组合促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'promotionInstructions',
                name: 'promotionInstructions',
                component: () =>
                    import('@/views/PromotionManagement/promotionInstructions'),
                meta: {
                    title: '促销说明管理',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamSetting',
                name: 'priceChangeParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSetting'),
                meta: {
                    title: '变价参数配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamQuickSetting',
                name: 'priceChangeParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramQuickSetting'),
                meta: {
                    title: '变价参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamDetail',
                name: 'priceChangeParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSettingDetail'),
                meta: {
                    title: '变价参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeListSetting',
                name: 'priceChangeListSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/listSetting'),
                meta: {
                    title: '变价列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamSetting',
                name: 'mixPromotionParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSetting'),
                meta: {
                    title: '组合促销参数设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamQuickSetting',
                name: 'mixPromotionParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramQuickSetting'),
                meta: {
                    title: '组合参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamDetail',
                name: 'mixPromotionParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSettingDetail'),
                meta: {
                    title: '组合参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionListSetting',
                name: 'mixPromotionListSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/listSetting'),
                meta: {
                    title: '组合列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myReports',
                name: 'myReports',
                component: () => import('@/views/configureReports/myLike'),
                meta: { title: '我的收藏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'table',
                name: 'table',
                component: () => import('@/views/configurationForm/table'),
                meta: {
                    title: '配置表单静态用table',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/configurationForm/form'),
                meta: {
                    title: '配置表单静态用form',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formtab',
                name: 'formtab',
                component: () => import('@/views/configurationForm/tabs'),
                meta: {
                    title: '配置表单静态用tab',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: {
                    title: '接口数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: {
                    title: '接口数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: {
                    title: '抛送数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: {
                    title: '抛送数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: {
                    title: '接口对外文档',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'processControl',
            //   name: 'processControl',
            //   component: () => import('@/views/processDesign/processControl/index'),
            //   meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            // },

            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () =>
                    import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessIndex'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessMain.vue'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () =>
                    import('@/views/processDesign/processManage/myProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: {
                    title: '打印模板设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: {
                    title: '打印模板设置新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: {
                    title: '打印模板列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: {
                    title: '打印模板列表新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'developNeeds',
                name: 'developNeeds',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '开发需求', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onlinePlan',
                name: 'onlinePlan',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '上线计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('@/views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false } //首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('@/views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('@/views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('@/views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('@/views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'informFile',
                name: 'informFile',
                component: () => import('@/views/Inform/tab'),
                meta: { title: '通知文件', icon: 'el-icon-user-solid', affix: false }
            },
            // 瑞克斯新增路由
            {
                path: 'waybillList',
                name: 'waybillList',
                component: () => import('@/views/dispatch/waybillList/list'),
                meta: { title: '运单管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waybillConfirm',
                name: 'waybillConfirm',
                component: () =>
                    import('@/views/dispatch/waybillConfirm/waybillConfirm'),
                meta: { title: '运单确认', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'distributionPeportGoods',
                name: 'distributionPeportGoods',
                component: () =>
                    import('@/views/dispatch/distributionPeportGoods/list'),
                meta: { title: '配货报货', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupDispatchList',
                name: 'pickupDispatchList',
                component: () => import('@/views/dispatch/pickupDispatchList/list'),
                meta: { title: '提货派车', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupHandover',
                name: 'pickupHandover',
                component: () => import('@/views/dispatch/pickupHandover/list'),
                meta: { title: '提货交接', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onwayList',
                name: 'onwayList',
                component: () => import('@/views/onway/onwayList/list'),
                meta: { title: '在途管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'travelMap',
                name: 'travelMap',
                component: () => import('@/views/onway/travel/map'),
                meta: { title: '轨迹追踪', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'stowageTransfer',
                name: 'stowageTransfer',
                component: () => import('@/views/onway/stowageTransfer/list'),
                meta: { title: '配载中转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'deliverySignin',
                name: 'deliverySignin',
                component: () => import('@/views/onway/deliverySignin/list'),
                meta: { title: '派送签收', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'abnormalList',
                name: 'abnormalList',
                component: () => import('@/views/onway/abnormalList/list'),
                meta: { title: '异常管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPermission',
                name: 'userPermission',
                component: () => import('@/views/userPermission/list'),
                meta: {
                    title: '用户权限配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'KPITimelinessRate',
                name: 'KPITimelinessRate',
                component: () => import('@/views/tmsDialog/KPITimelinessRate'),
                meta: { title: '到货及时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIOnTimeRate',
                name: 'KPIOnTimeRate',
                component: () => import('@/views/tmsDialog/KPIOnTimeRate'),
                meta: { title: '提货准时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIEfficiency',
                name: 'KPIEfficiency',
                component: () => import('@/views/tmsDialog/KPIEfficiency'),
                meta: { title: '提货效率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SCMKremainProcess',
                name: 'SCMKremainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/SCMKremainProcess/tab'),
                meta: {
                    title: '生产模块-待办任务',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/taskAgents/addProcessIndex'
                    ),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'sampleDetail',
                name: 'sampleDetail',
                component: () => import('@/views/sampleDetail/index'),
                meta: {
                    title: '戎美样衣详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'jumpurl',
                name: 'jumpurl',
                component: () => import('@/views/jumpUrl'),
                meta: { title: '跳转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpTo/:menuid',
                name: 'jumpTo',
                component: () => import('@/components/commonData/jumpTo'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAReport',
                name: 'BAReport',
                component: () => import('@/views/BAReport/index'),
                meta: { title: '项目信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAFundReport',
                name: 'BAFundReport',
                component: () => import('@/views/BAReport/BAFundReport'),
                meta: {
                    title: '项目资金报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('@/views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('@/views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'customTableScreen',
                name: 'customTableScreen',
                component: () => import('@/views/showScreen/tableScreen'),
                meta: { title: '戎美定制大屏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'saleScreenConfiguration',
                name: 'saleScreenConfiguration',
                component: () => import('@/views/showScreen/saleTableConfiguration'),
                meta: { title: '销售大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            }
        ]
    },
    //戎美售后
    {
        path: '/receiptVoucher',
        name: 'receiptVoucher',
        component: () => import('@/views/RMAfterSaleSys/receiptVoucher'),
        meta: { title: '入库制单', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/ExpressUnpacking',
        name: 'ExpressUnpacking',
        component: () => import('@/views/RMAfterSaleSys/ExpressUnpacking'),
        meta: { title: '快递拆包', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/OutorderSearch',
        name: 'OutorderSearch',
        component: () => import('@/views/RMAfterSaleSys/OutorderSearch'),
        meta: { title: '出库单查询', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/AddOut',
        name: 'AddOut',
        component: () => import('@/views/RMAfterSaleSys/AddOut'),
        meta: { title: '出库单新增', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Refund',
        name: 'Refund',
        component: () => import('@/views/RMAfterSaleSys/Refund'),
        meta: { title: '退款', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Maintenance',
        name: 'Maintenance',
        component: () => import('@/views/RMAfterSaleSys/Maintenance'),
        meta: { title: '维修单', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/QualityTest',
        name: 'QualityTest',
        component: () => import('@/views/RMAfterSaleSys/QualityTest'),
        meta: { title: '质检', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Anomaly',
        name: 'Anomaly',
        component: () => import('@/views/RMAfterSaleSys/Anomaly'),
        meta: { title: '异常处理', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/RefundAnomaly',
        name: 'RefundAnomaly',
        component: () => import('@/views/RMAfterSaleSys/RefundAnomaly'),
        meta: { title: '退款异常处理', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showScreen/tableScreen',
        name: 'tableScreen',
        component: () => import('@/views/showScreen/tableScreen'),
        meta: { title: '戎美定制大屏', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showScreen/tableScreenLogin',
        name: 'tableScreenLogin',
        component: () => import('@/views/showScreen/tableScreenLogin'),
        meta: { title: '戎美定制大屏登录', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showScreen/newLive',
        name: 'newLive',
        component: () => import('@/views/showScreen/newLive'),
        meta: { title: '戎美定制大屏2', icon: 'el-icon-user-solid', affix: false }
    },
    // {
    //   path: '/showScreen/customTableScreen',
    //   name: 'customTableScreen',
    //   component: () => import('@/views/showScreen/tableScreen'),
    //   meta: { title: '戎美定制大屏', icon: 'el-icon-user-solid', affix: false }
    // },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('@/views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('@/views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    }
]

export const routerDataSY = [
    {
        path: '/',
        name: 'loginIndex',
        component: () => import('@/views/sylogin')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/sylogin')
    },
    //首页
    {
        path: '/HomePage',
        name: 'HomePage',
        component: () => import('@/views/HomePage/index')
    },
    {
        path: '/inputFingerprint',
        name: 'inputFingerprint',
        component: () => import('@/views/inputFingerprint')
    },
    {
        path: '/flipBook',
        name: 'flipBook',
        component: () => import('@/views/threeD/threeD-train/flipBook-train')
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: () => import('@/views/home'),
        children: [
            {
                path: 'floor',
                name: 'floor',
                component: () => import('@/views/threeD/floor'),
                meta: { title: '3D楼层', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'floorIframe',
                name: 'floorIframe',
                component: () => import('@/views/threeD/floorIframe'),
                meta: {
                    title: '3D楼层平面版',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'caseNo',
                name: 'caseNo',
                component: () => import('@/views/threeD/caseNo'),
                meta: {
                    title: '案件数据抽取',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/dashboardhome'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'perExamine',
                name: 'perExamine',
                component: () => import('@/views/singlePage/perExamine'),
                meta: { title: '注册审核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: {
                    title: '任务管理平台',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'userIndex',
                name: 'UserIndex',
                component: () => import('@/views/UserIndex'),
                meta: { title: '人员信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userDetail',
                name: 'UserDetail',
                component: () => import('@/views/UserDetail'),
                meta: { title: '人员详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'management',
                name: 'Management',
                component: () => import('@/views/FileManagement/management'),
                meta: {
                    title: '管理架构维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'postMaintenance',
                name: 'PostMaintenance',
                component: () => import('@/views/FileManagement/postMaintenance'),
                meta: {
                    title: '岗位档案维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // {
            //   path: 'withdrawal',
            //   name: 'Withdrawal',
            //   component: ()=>import('@/views/Withdrawal/withdrawal') ,
            //   meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'Withdrawalorder',
                name: 'Withdrawalorder',
                component: () => import('@/views/Withdrawal/Withdrawalorder'),
                meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'withdrawalDetails',
            //   name: 'withdrawalDetails',
            //   component: ()=>import('@/views/Withdrawal/withdrawalDetails') ,
            //   meta: { title: '商品明细', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'withdrawalOrderDetails',
                name: 'withdrawalOrderDetails',
                component: () => import('@/views/Withdrawal/withdrawalOrderDetails'),
                meta: {
                    title: '门店退仓商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'scancodeOrder',
                name: 'scancodeOrder',
                component: () => import('@/views/Withdrawal/scancodeOrder'),
                meta: {
                    title: '门店退仓扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersIn',
                name: 'transfersIn',
                component: () => import('@/views/Transfers/transfersIn'),
                meta: {
                    title: '门店调拨-转入',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersInDetail',
                name: 'transfersInDetail',
                component: () => import('@/views/Transfers/transfersInDetail'),
                meta: {
                    title: '门店调拨转入明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'transfersOut',
                name: 'transfersOut',
                component: () => import('@/views/Transfers/transfersIndex'),
                meta: {
                    title: '门店调拨-转出',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersOutDetail',
                name: 'transfersOutDetail',
                component: () => import('@/views/Transfers/transfersDetail'),
                meta: {
                    title: '门店调拨转出明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancode',
                name: 'tranScancode',
                component: () => import('@/views/Transfers/scancode'),
                meta: {
                    title: '门店转出扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancodeIn',
                name: 'tranScancodeIn',
                component: () => import('@/views/Transfers/scancodeIn'),
                meta: {
                    title: '门店转入扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'receiving',
                name: 'receiving',
                component: () => import('@/views/Receiving/receiving'),
                meta: { title: '收货复核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receivingDetails',
                name: 'receivingDetails',
                component: () => import('@/views/Receiving/receivingDetails'),
                meta: {
                    title: '收货复核商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'recevescancode',
                name: 'recevescancode',
                component: () => import('@/views/Receiving/recevescancode'),
                meta: {
                    title: '收货复核扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'OutboundReturnReport',
                name: 'OutboundReturnReport',
                component: () => import('@/views/storeReport/OutboundReturnReport'),
                meta: {
                    title: '门店出库退货查询',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: {
                    title: '配置报表展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: {
                    title: '配置表单展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: {
                    title: '配置表单分类展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'InvoicingReport',
                name: 'InvoicingReport',
                component: () => import('@/views/storeReport/InvoicingReport'),
                meta: {
                    title: '仓店任意日进销存报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'posindex',
                name: 'posindex',
                component: () => import('@/views/Pos/posindex'),
                meta: { title: '在线POS', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlan',
                name: 'StoreInventoryPlan',
                component: () => import('@/views/StoreInventory/StoreInventoryPlan'),
                meta: {
                    title: '门店盘点计划',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryPlanDetail',
                name: 'StoreInventoryPlanDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryPlanDetail'),
                meta: {
                    title: '门店盘点计划明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryList',
                name: 'StoreInventoryList',
                component: () => import('@/views/StoreInventory/StoreInventoryList'),
                meta: { title: '门店盘点单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryListDetail',
                name: 'StoreInventoryListDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryListDetail'),
                meta: {
                    title: '门店盘点单详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreAdjustmentList',
                name: 'StoreAdjustmentList',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentList'),
                meta: { title: '门店调整单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentDetail',
                name: 'StoreAdjustmentDetail',
                component: () =>
                    import('@/views/StoreAdjustment/StoreAdjustmentDetail'),
                meta: {
                    title: '门店调整单明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },

            // {
            //   path:'carouselScreen/:id',
            //   name:'carouselScreen',
            //   component:()=>import('@/views/show/carouselScreen') ,
            //   meta: { title: '大屏组合轮播', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'priceChangePromotion',
                name: 'priceChangePromotion',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotion'),
                meta: { title: '变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotionDetail',
                name: 'priceChangePromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotionDetail'),
                meta: {
                    title: '编辑变价促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeBatchModify',
                name: 'priceChangeBatchModify',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/batchModify'),
                meta: {
                    title: '变价促销批量修改',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionPromotion',
                name: 'mixPromotionPromotion',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotion'),
                meta: { title: '组合促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotionDetail',
                name: 'mixPromotionPromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotionDetail'),
                meta: {
                    title: '编辑组合促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'promotionInstructions',
                name: 'promotionInstructions',
                component: () =>
                    import('@/views/PromotionManagement/promotionInstructions'),
                meta: {
                    title: '促销说明管理',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamSetting',
                name: 'priceChangeParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSetting'),
                meta: {
                    title: '变价参数配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamQuickSetting',
                name: 'priceChangeParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramQuickSetting'),
                meta: {
                    title: '变价参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamDetail',
                name: 'priceChangeParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSettingDetail'),
                meta: {
                    title: '变价参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeListSetting',
                name: 'priceChangeListSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/listSetting'),
                meta: {
                    title: '变价列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamSetting',
                name: 'mixPromotionParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSetting'),
                meta: {
                    title: '组合促销参数设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamQuickSetting',
                name: 'mixPromotionParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramQuickSetting'),
                meta: {
                    title: '组合参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamDetail',
                name: 'mixPromotionParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSettingDetail'),
                meta: {
                    title: '组合参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionListSetting',
                name: 'mixPromotionListSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/listSetting'),
                meta: {
                    title: '组合列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myReports',
                name: 'myReports',
                component: () => import('@/views/configureReports/myLike'),
                meta: { title: '我的收藏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'table',
                name: 'table',
                component: () => import('@/views/configurationForm/table'),
                meta: {
                    title: '配置表单静态用table',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/configurationForm/form'),
                meta: {
                    title: '配置表单静态用form',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formtab',
                name: 'formtab',
                component: () => import('@/views/configurationForm/tabs'),
                meta: {
                    title: '配置表单静态用tab',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: {
                    title: '接口数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: {
                    title: '接口数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: {
                    title: '抛送数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: {
                    title: '抛送数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: {
                    title: '接口对外文档',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'processControl',
            //   name: 'processControl',
            //   component: () => import('@/views/processDesign/processControl/index'),
            //   meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            // },

            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () =>
                    import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessIndex'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessMain.vue'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () =>
                    import('@/views/processDesign/processManage/myProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: {
                    title: '打印模板设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: {
                    title: '打印模板设置新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: {
                    title: '打印模板列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: {
                    title: '打印模板列表新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'developNeeds',
                name: 'developNeeds',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '开发需求', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onlinePlan',
                name: 'onlinePlan',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '上线计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('@/views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false } //首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('@/views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('@/views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('@/views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('@/views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'informFile',
                name: 'informFile',
                component: () => import('@/views/Inform/tab'),
                meta: { title: '通知文件', icon: 'el-icon-user-solid', affix: false }
            },
            // 瑞克斯新增路由
            {
                path: 'waybillList',
                name: 'waybillList',
                component: () => import('@/views/dispatch/waybillList/list'),
                meta: { title: '运单管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waybillConfirm',
                name: 'waybillConfirm',
                component: () =>
                    import('@/views/dispatch/waybillConfirm/waybillConfirm'),
                meta: { title: '运单确认', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'distributionPeportGoods',
                name: 'distributionPeportGoods',
                component: () =>
                    import('@/views/dispatch/distributionPeportGoods/list'),
                meta: { title: '配货报货', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupDispatchList',
                name: 'pickupDispatchList',
                component: () => import('@/views/dispatch/pickupDispatchList/list'),
                meta: { title: '提货派车', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupHandover',
                name: 'pickupHandover',
                component: () => import('@/views/dispatch/pickupHandover/list'),
                meta: { title: '提货交接', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onwayList',
                name: 'onwayList',
                component: () => import('@/views/onway/onwayList/list'),
                meta: { title: '在途管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'travelMap',
                name: 'travelMap',
                component: () => import('@/views/onway/travel/map'),
                meta: { title: '轨迹追踪', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'stowageTransfer',
                name: 'stowageTransfer',
                component: () => import('@/views/onway/stowageTransfer/list'),
                meta: { title: '配载中转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'deliverySignin',
                name: 'deliverySignin',
                component: () => import('@/views/onway/deliverySignin/list'),
                meta: { title: '派送签收', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'abnormalList',
                name: 'abnormalList',
                component: () => import('@/views/onway/abnormalList/list'),
                meta: { title: '异常管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPermission',
                name: 'userPermission',
                component: () => import('@/views/userPermission/list'),
                meta: {
                    title: '用户权限配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'KPITimelinessRate',
                name: 'KPITimelinessRate',
                component: () => import('@/views/tmsDialog/KPITimelinessRate'),
                meta: { title: '到货及时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIOnTimeRate',
                name: 'KPIOnTimeRate',
                component: () => import('@/views/tmsDialog/KPIOnTimeRate'),
                meta: { title: '提货准时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIEfficiency',
                name: 'KPIEfficiency',
                component: () => import('@/views/tmsDialog/KPIEfficiency'),
                meta: { title: '提货效率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SCMKremainProcess',
                name: 'SCMKremainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/SCMKremainProcess/tab'),
                meta: {
                    title: '生产模块-待办任务',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/taskAgents/addProcessIndex'
                    ),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpurl',
                name: 'jumpurl',
                component: () => import('@/views/jumpUrl'),
                meta: { title: '跳转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpTo/:menuid',
                name: 'jumpTo',
                component: () => import('@/components/commonData/jumpTo'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAReport',
                name: 'BAReport',
                component: () => import('@/views/BAReport/index'),
                meta: { title: '项目信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAFundReport',
                name: 'BAFundReport',
                component: () => import('@/views/BAReport/BAFundReport'),
                meta: {
                    title: '项目资金报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            //配置大屏、BI
            {
                path: 'Settingscreen',
                name: 'Settingscreen',
                component: () => import('@/views/setting/settingScreen'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingScreenN',
                name: 'settingScreenN',
                component: () => import('@/views/screenSettingNew/tab'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toCsetting',
                name: 'toCsetting',
                component: () => import('@/views/setting/toCPlussetting'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('@/views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('@/views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'inputFingerprint1',
                name: 'inputFingerprint1',
                component: () => import('@/views/inputFingerprint'),
                meta: { title: '录入指纹', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BulletinBoardConfiguration',
                name: 'BulletinBoardConfiguration',
                component: () => import('@/views/BulletinBoardConfiguration/index'),
                meta: { title: '公告栏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            }
        ]
    },
    {
        path: '/show/:pageId',
        name: 'bigshowScreen',
        component: () => import('@/views/show/showScreen'),
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Settingscreen',
        name: 'bigSettingscreen',
        component: () => import('@/views/setting/settingScreen'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/SettingBIReport',
        name: 'bigSettingBIReport',
        component: () => import('@/views/BIConfiguration/index'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bsettingScreenN',
        name: 'bsettingScreenN',
        component: () => import('@/views/screenSettingNew/tab'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/carouselScreen/:id',
        name: 'carouselScreen',
        component: () => import('@/views/show/carouselScreen'),
        meta: { title: '大屏组合轮播', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('@/views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('@/views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test')
    },
    {
        path: '/bprocess',
        name: 'bprocess',
        component: () =>
            import('@/views/processDesign/processControl/bigprocessmodify'),
        meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/echartspage',
        name: 'echartspage',
        component: () => import('@/views/EchartsPage/echartspage'),
        meta: { title: 'echarts', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/caseguide',
        name: 'caseguide',
        component: () => import('@/views/PropertyManagesystem/caseGuide'),
        meta: {
            title: '常熟市涉案财物管理中心',
            icon: 'el-icon-user-solid',
            affix: false
        }
    },
    {
        path: '/uploadFace',
        name: 'uploadFace',
        component: () => import('@/views/customMade/uploadFace'),
        meta: { title: '人脸上传', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/BIConfiguration',
        name: 'BIConfiguration',
        component: () => import('@/views/BIConfiguration/BIConfiguration'),
        meta: { title: 'BI参数配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/timelineTree',
        name: 'timelineTree',
        component: () => import('@/views/customMade/timelineTree'),
        meta: { title: '时间轴树形图', icon: 'el-icon-user-solid', affix: false }
    }
]

export const routerDataJCZX = [
    {
        path: '/redirect',
        component: () => import('@/views/redirect/index'),
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)'
            }
        ]
    },
    {
        path: '/jczxEnterpage',
        name: 'jczxEnterpage',
        component: () => import('@/views/aloneViews/jczx/jczxEnterpage'),
        meta: {
            title: '智慧检测综合服务平台',
            icon: 'el-icon-user-solid',
            affix: false
        }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test.vue')
    },
    {
        path: '/',
        name: 'loginIndex',
        component: () => import('@/views/jczxlogin')
        // component: resolve => require(['@/views/jczxlogin'], resolve)
    },
    {
        path: '/login', //普通登录
        name: 'login',
        component: () => import('@/views/jczxlogin')
        // component: resolve => require(['@/views/jczxlogin'], resolve)
    },
    {
        path: '/jczxlogin', //检测中心登录
        name: 'jczxlogin',
        component: () => import('@/views/jczxlogin')
        // component: resolve => require(['@/views/jczxlogin'], resolve)
    },
    {
        path: '/verifyReport',
        name: 'verifyReport',
        component: () => import('@/views/aloneViews/jczx/verifyReport'),
        meta: { title: '检验报告', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/jczxHomePage',
        name: 'jczxHomePage',
        component: () =>
            import('@/views/aloneViews/jczx/jczxHomePage/jczxHomePage'),
        meta: { title: '首页', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/jczxNotice',
        name: 'jczxNotice',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxNotice') //通知公告
    },
    {
        path: '/jczxNoticeDitails',
        name: 'jczxNoticeDitails',
        component: () =>
            import('@/views/aloneViews/jczx/jczxHomePage/jczxNoticeDitails') //通知公告
    },
    //   {
    //     path: '/jczxWorkStatus',
    //     name: 'jczxWorkStatus',
    //     component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxWorkStatus') //工作动态
    //   },
    {
        path: '/jczxWork',
        name: 'jczxWork',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxWork') //工作动态
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/aboutUs') //联系我们
    },

    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: () => import('@/views/home'),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/dashboardhome'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'perExamine',
                name: 'perExamine',
                component: () => import('@/views/singlePage/perExamine'),
                meta: { title: '注册审核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: {
                    title: '任务管理平台',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'userIndex',
                name: 'UserIndex',
                component: () => import('@/views/UserIndex'),
                meta: { title: '人员信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userDetail',
                name: 'UserDetail',
                component: () => import('@/views/UserDetail'),
                meta: { title: '人员详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'management',
                name: 'Management',
                component: () => import('@/views/FileManagement/management'),
                meta: {
                    title: '管理架构维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'postMaintenance',
                name: 'PostMaintenance',
                component: () => import('@/views/FileManagement/postMaintenance'),
                meta: {
                    title: '岗位档案维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'Withdrawalorder',
                name: 'Withdrawalorder',
                component: () => import('@/views/Withdrawal/Withdrawalorder'),
                meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'withdrawalOrderDetails',
                name: 'withdrawalOrderDetails',
                component: () => import('@/views/Withdrawal/withdrawalOrderDetails'),
                meta: {
                    title: '门店退仓商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'scancodeOrder',
                name: 'scancodeOrder',
                component: () => import('@/views/Withdrawal/scancodeOrder'),
                meta: {
                    title: '门店退仓扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersIn',
                name: 'transfersIn',
                component: () => import('@/views/Transfers/transfersIn'),
                meta: {
                    title: '门店调拨-转入',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersInDetail',
                name: 'transfersInDetail',
                component: () => import('@/views/Transfers/transfersInDetail'),
                meta: {
                    title: '门店调拨转入明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'transfersOut',
                name: 'transfersOut',
                component: () => import('@/views/Transfers/transfersIndex'),
                meta: {
                    title: '门店调拨-转出',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersOutDetail',
                name: 'transfersOutDetail',
                component: () => import('@/views/Transfers/transfersDetail'),
                meta: {
                    title: '门店调拨转出明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancode',
                name: 'tranScancode',
                component: () => import('@/views/Transfers/scancode'),
                meta: {
                    title: '门店转出扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancodeIn',
                name: 'tranScancodeIn',
                component: () => import('@/views/Transfers/scancodeIn'),
                meta: {
                    title: '门店转入扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'receiving',
                name: 'receiving',
                component: () => import('@/views/Receiving/receiving'),
                meta: { title: '收货复核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receivingDetails',
                name: 'receivingDetails',
                component: () => import('@/views/Receiving/receivingDetails'),
                meta: {
                    title: '收货复核商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'recevescancode',
                name: 'recevescancode',
                component: () => import('@/views/Receiving/recevescancode'),
                meta: {
                    title: '收货复核扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'OutboundReturnReport',
                name: 'OutboundReturnReport',
                component: () => import('@/views/storeReport/OutboundReturnReport'),
                meta: {
                    title: '门店出库退货查询',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: {
                    title: '配置报表展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: {
                    title: '配置表单展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: {
                    title: '配置表单分类展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'InvoicingReport',
                name: 'InvoicingReport',
                component: () => import('@/views/storeReport/InvoicingReport'),
                meta: {
                    title: '仓店任意日进销存报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'posindex',
                name: 'posindex',
                component: () => import('@/views/Pos/posindex'),
                meta: { title: '在线POS', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlan',
                name: 'StoreInventoryPlan',
                component: () => import('@/views/StoreInventory/StoreInventoryPlan'),
                meta: {
                    title: '门店盘点计划',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryPlanDetail',
                name: 'StoreInventoryPlanDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryPlanDetail'),
                meta: {
                    title: '门店盘点计划明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryList',
                name: 'StoreInventoryList',
                component: () => import('@/views/StoreInventory/StoreInventoryList'),
                meta: { title: '门店盘点单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryListDetail',
                name: 'StoreInventoryListDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryListDetail'),
                meta: {
                    title: '门店盘点单详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreAdjustmentList',
                name: 'StoreAdjustmentList',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentList'),
                meta: { title: '门店调整单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentDetail',
                name: 'StoreAdjustmentDetail',
                component: () =>
                    import('@/views/StoreAdjustment/StoreAdjustmentDetail'),
                meta: {
                    title: '门店调整单明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'carouselScreen/:id',
                name: 'carouselScreen',
                component: () => import('@/views/show/carouselScreen'),
                meta: {
                    title: '大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangePromotion',
                name: 'priceChangePromotion',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotion'),
                meta: { title: '变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotionDetail',
                name: 'priceChangePromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotionDetail'),
                meta: {
                    title: '编辑变价促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeBatchModify',
                name: 'priceChangeBatchModify',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/batchModify'),
                meta: {
                    title: '变价促销批量修改',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionPromotion',
                name: 'mixPromotionPromotion',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotion'),
                meta: { title: '组合促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotionDetail',
                name: 'mixPromotionPromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotionDetail'),
                meta: {
                    title: '编辑组合促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'promotionInstructions',
                name: 'promotionInstructions',
                component: () =>
                    import('@/views/PromotionManagement/promotionInstructions'),
                meta: {
                    title: '促销说明管理',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamSetting',
                name: 'priceChangeParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSetting'),
                meta: {
                    title: '变价参数配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamQuickSetting',
                name: 'priceChangeParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramQuickSetting'),
                meta: {
                    title: '变价参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamDetail',
                name: 'priceChangeParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSettingDetail'),
                meta: {
                    title: '变价参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeListSetting',
                name: 'priceChangeListSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/listSetting'),
                meta: {
                    title: '变价列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamSetting',
                name: 'mixPromotionParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSetting'),
                meta: {
                    title: '组合促销参数设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamQuickSetting',
                name: 'mixPromotionParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramQuickSetting'),
                meta: {
                    title: '组合参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamDetail',
                name: 'mixPromotionParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSettingDetail'),
                meta: {
                    title: '组合参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionListSetting',
                name: 'mixPromotionListSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/listSetting'),
                meta: {
                    title: '组合列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myReports',
                name: 'myReports',
                component: () => import('@/views/configureReports/myLike'),
                meta: { title: '我的收藏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'table',
                name: 'table',
                component: () => import('@/views/configurationForm/table'),
                meta: {
                    title: '配置表单静态用table',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/configurationForm/form'),
                meta: {
                    title: '配置表单静态用form',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formtab',
                name: 'formtab',
                component: () => import('@/views/configurationForm/tabs'),
                meta: {
                    title: '配置表单静态用tab',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: {
                    title: '接口数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: {
                    title: '接口数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: {
                    title: '抛送数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: {
                    title: '抛送数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: {
                    title: '接口对外文档',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () =>
                    import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessIndex'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessMain.vue'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () =>
                    import('@/views/processDesign/processManage/myProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: {
                    title: '打印模板设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: {
                    title: '打印模板设置新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: {
                    title: '打印模板列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: {
                    title: '打印模板列表新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'developNeeds',
                name: 'developNeeds',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '开发需求', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onlinePlan',
                name: 'onlinePlan',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '上线计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('@/views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false } //首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('@/views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('@/views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('@/views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('@/views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'informFile',
                name: 'informFile',
                component: () => import('@/views/Inform/tab'),
                meta: { title: '通知文件', icon: 'el-icon-user-solid', affix: false }
            },
            // 瑞克斯新增路由
            {
                path: 'waybillList',
                name: 'waybillList',
                component: () => import('@/views/dispatch/waybillList/list'),
                meta: { title: '运单管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waybillConfirm',
                name: 'waybillConfirm',
                component: () =>
                    import('@/views/dispatch/waybillConfirm/waybillConfirm'),
                meta: { title: '运单确认', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'distributionPeportGoods',
                name: 'distributionPeportGoods',
                component: () =>
                    import('@/views/dispatch/distributionPeportGoods/list'),
                meta: { title: '配货报货', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupDispatchList',
                name: 'pickupDispatchList',
                component: () => import('@/views/dispatch/pickupDispatchList/list'),
                meta: { title: '提货派车', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupHandover',
                name: 'pickupHandover',
                component: () => import('@/views/dispatch/pickupHandover/list'),
                meta: { title: '提货交接', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onwayList',
                name: 'onwayList',
                component: () => import('@/views/onway/onwayList/list'),
                meta: { title: '在途管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'travelMap',
                name: 'travelMap',
                component: () => import('@/views/onway/travel/map'),
                meta: { title: '轨迹追踪', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'stowageTransfer',
                name: 'stowageTransfer',
                component: () => import('@/views/onway/stowageTransfer/list'),
                meta: { title: '配载中转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'deliverySignin',
                name: 'deliverySignin',
                component: () => import('@/views/onway/deliverySignin/list'),
                meta: { title: '派送签收', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'abnormalList',
                name: 'abnormalList',
                component: () => import('@/views/onway/abnormalList/list'),
                meta: { title: '异常管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPermission',
                name: 'userPermission',
                component: () => import('@/views/userPermission/list'),
                meta: {
                    title: '用户权限配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'KPITimelinessRate',
                name: 'KPITimelinessRate',
                component: () => import('@/views/tmsDialog/KPITimelinessRate'),
                meta: { title: '到货及时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIOnTimeRate',
                name: 'KPIOnTimeRate',
                component: () => import('@/views/tmsDialog/KPIOnTimeRate'),
                meta: { title: '提货准时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIEfficiency',
                name: 'KPIEfficiency',
                component: () => import('@/views/tmsDialog/KPIEfficiency'),
                meta: { title: '提货效率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SCMKremainProcess',
                name: 'SCMKremainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/SCMKremainProcess/tab'),
                meta: {
                    title: '生产模块-待办任务',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/taskAgents/addProcessIndex'
                    ),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpurl',
                name: 'jumpurl',
                component: () => import('@/views/jumpUrl'),
                meta: { title: '跳转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpTo/:menuid',
                name: 'jumpTo',
                component: () => import('@/components/commonData/jumpTo'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAReport',
                name: 'BAReport',
                component: () => import('@/views/BAReport/index'),
                meta: { title: '项目信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAFundReport',
                name: 'BAFundReport',
                component: () => import('@/views/BAReport/BAFundReport'),
                meta: {
                    title: '项目资金报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            //配置大屏、BI
            {
                path: 'Settingscreen',
                name: 'Settingscreen',
                component: () => import('@/views/setting/settingScreen'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingScreenN',
                name: 'settingScreenN',
                component: () => import('@/views/screenSettingNew/tab'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toBIsetting',
                name: 'toBIsetting',
                component: () => import('@/views/setting/toBIsetting'),
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReport',
                name: 'SettingBIReport',
                component: () => import('@/views/BIConfiguration/index'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReportN',
                name: 'SettingBIReportN',
                component: () => import('@/views/BIConfiguration/indexNew'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'show/:pageId',
                name: 'showScreen',
                component: () => import('@/views/show/showScreen'),
                meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'chooseSwiperScreen',
                name: 'chooseSwiperScreen',
                component: () => import('@/views/setting/chooseSwiperScreen'),
                meta: {
                    title: '设置大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'carouselScreenList',
                name: 'carouselScreenList',
                component: () => import('@/views/show/carouselScreenList'),
                meta: {
                    title: '大屏组合轮播列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('@/views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('@/views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },
            // 树形表格
            {
                path: 'treeTable/:menuid',
                name: 'treeTable',
                component: () => import('@/views/treeTable/treeTable'),
                meta: { title: '部门信息', icon: 'el-icon-user-solid', affix: false }
            },
            //树型菜单
            {
                path: 'treeMenu',
                name: 'treeMenu',
                //component: () => import('@/views/login')
                component: resolve =>
                    require(['@/views/configurationForm/treeMenu'], resolve)
            },
            //调度中心页面
            {
                path: 'dispatchingCenter',
                name: 'dispatchingCenter',
                component: () =>
                    import('@/views/aloneViews/pg/dispatchingCenter/index'),
                meta: { title: '调度中心', icon: 'el-icon-user-solid', affix: false }
            },
            //人员画像
            {
                path: 'perPortrait',
                name: 'perPortrait',
                component: () => import('@/views/aloneViews/pg/perPortrait/index'),
                meta: { title: '人员画像', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dialogSearchJL',
                name: 'dialogSearchJL',
                component: () => import('@/views/aloneViews/jczx/dialogSearchJL'),
                meta: { title: '弹窗', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'appointmentIndex',
                name: 'appointmentIndex',
                component: () => import('@/views/aloneViews/jczx/appointmentIndex'),
                meta: { title: '计量所首页', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'qualityInspectionInstitute',
                name: 'qualityInspectionInstitute',
                component: () =>
                    import('@/views/aloneViews/jczx/qualityInspectionInstitute'),
                meta: { title: '质检所首页', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            }
        ]
    },
    {
        path: '/show/:pageId',
        name: 'bigshowScreen',
        component: () => import('@/views/show/showScreen'),
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('@/views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('@/views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bprocess',
        name: 'bprocess',
        component: () =>
            import('@/views/processDesign/processControl/bigprocessmodify'),
        meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/echartspage',
        name: 'echartspage',
        component: () => import('@/views/EchartsPage/echartspage'),
        meta: { title: 'echarts', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/caseguide',
        name: 'caseguide',
        component: () => import('@/views/PropertyManagesystem/caseGuide'),
        meta: {
            title: '涉案财物管理系统',
            icon: 'el-icon-user-solid',
            affix: false
        }
    },
    {
        path: '/jczxcaseGuide',
        name: 'jczxcaseGuide',
        component: () => import('@/views/aloneViews/jczx/jczxcaseGuide'),
        meta: {
            title: '智慧检测综合服务平台',
            icon: 'el-icon-user-solid',
            affix: false
        }
    }
]


export const routerDataJJ = [
    {
        path: '/',
        name: 'loginIndex',
        component: () => import('@/views/login')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: () => import('@/views/home'),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/demo'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'perExamine',
                name: 'perExamine',
                component: () => import('@/views/singlePage/perExamine'),
                meta: { title: '注册审核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: { title: '任务管理平台', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userIndex',
                name: 'UserIndex',
                component: () => import('@/views/UserIndex'),
                meta: { title: '人员信息', icon: 'el-icon-user-solid', affix: false }
            }
            ,
            {
                path: 'userDetail',
                name: 'UserDetail',
                component: () => import('@/views/UserDetail'),
                meta: { title: '人员详情', icon: 'el-icon-user-solid', affix: false }
            }
            ,
            {
                path: 'management',
                name: 'Management',
                component: () => import('@/views/FileManagement/management'),
                meta: { title: '管理架构维护', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'postMaintenance',
                name: 'PostMaintenance',
                component: () => import('@/views/FileManagement/postMaintenance'),
                meta: { title: '岗位档案维护', icon: 'el-icon-user-solid', affix: false }
            },

            // {
            //   path: 'withdrawal',
            //   name: 'Withdrawal',
            //   component: ()=>import('@/views/Withdrawal/withdrawal') ,
            //   meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'Withdrawalorder',
                name: 'Withdrawalorder',
                component: () => import('@/views/Withdrawal/Withdrawalorder'),
                meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'withdrawalOrderDetails',
                name: 'withdrawalOrderDetails',
                component: () => import('@/views/Withdrawal/withdrawalOrderDetails'),
                meta: { title: '门店退仓商品明细', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'scancodeOrder',
                name: 'scancodeOrder',
                component: () => import('@/views/Withdrawal/scancodeOrder'),
                meta: { title: '门店退仓扫码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'transfersIn',
                name: 'transfersIn',
                component: () => import('@/views/Transfers/transfersIn'),
                meta: { title: '门店调拨-转入', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'transfersInDetail',
                name: 'transfersInDetail',
                component: () => import('@/views/Transfers/transfersInDetail'),
                meta: { title: '门店调拨转入明细', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'transfersOut',
                name: 'transfersOut',
                component: () => import('@/views/Transfers/transfersIndex'),
                meta: { title: '门店调拨-转出', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'transfersOutDetail',
                name: 'transfersOutDetail',
                component: () => import('@/views/Transfers/transfersDetail'),
                meta: { title: '门店调拨转出明细', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'tranScancode',
                name: 'tranScancode',
                component: () => import('@/views/Transfers/scancode'),
                meta: { title: '门店转出扫码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'tranScancodeIn',
                name: 'tranScancodeIn',
                component: () => import('@/views/Transfers/scancodeIn'),
                meta: { title: '门店转入扫码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receiving',
                name: 'receiving',
                component: () => import('@/views/Receiving/receiving'),
                meta: { title: '收货复核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receivingDetails',
                name: 'receivingDetails',
                component: () => import('@/views/Receiving/receivingDetails'),
                meta: { title: '收货复核商品明细', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'recevescancode',
                name: 'recevescancode',
                component: () => import('@/views/Receiving/recevescancode'),
                meta: { title: '收货复核扫码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'OutboundReturnReport',
                name: 'OutboundReturnReport',
                component: () => import('@/views/storeReport/OutboundReturnReport'),
                meta: { title: '门店出库退货查询', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: { title: '配置报表展示', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: { title: '配置表单展示', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: { title: '配置表单分类展示', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'InvoicingReport',
                name: 'InvoicingReport',
                component: () => import('@/views/storeReport/InvoicingReport'),
                meta: { title: '仓店任意日进销存报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'posindex',
                name: 'posindex',
                component: () => import('@/views/Pos/posindex'),
                meta: { title: '在线POS', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlan',
                name: 'StoreInventoryPlan',
                component: () => import('@/views/StoreInventory/StoreInventoryPlan'),
                meta: { title: '门店盘点计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlanDetail',
                name: 'StoreInventoryPlanDetail',
                component: () => import('@/views/StoreInventory/StoreInventoryPlanDetail'),
                meta: { title: '门店盘点计划明细', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryList',
                name: 'StoreInventoryList',
                component: () => import('@/views/StoreInventory/StoreInventoryList'),
                meta: { title: '门店盘点单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryListDetail',
                name: 'StoreInventoryListDetail',
                component: () => import('@/views/StoreInventory/StoreInventoryListDetail'),
                meta: { title: '门店盘点单详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentList',
                name: 'StoreAdjustmentList',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentList'),
                meta: { title: '门店调整单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentDetail',
                name: 'StoreAdjustmentDetail',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentDetail'),
                meta: { title: '门店调整单明细', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            }, {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotion',
                name: 'priceChangePromotion',
                component: () => import('@/views/PromotionManagement/priceChange/promotion'),
                meta: { title: '变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotionDetail',
                name: 'priceChangePromotionDetail',
                component: () => import('@/views/PromotionManagement/priceChange/promotionDetail'),
                meta: { title: '编辑变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangeBatchModify',
                name: 'priceChangeBatchModify',
                component: () => import('@/views/PromotionManagement/priceChange/batchModify'),
                meta: { title: '变价促销批量修改', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotion',
                name: 'mixPromotionPromotion',
                component: () => import('@/views/PromotionManagement/mixPromotion/promotion'),
                meta: { title: '组合促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotionDetail',
                name: 'mixPromotionPromotionDetail',
                component: () => import('@/views/PromotionManagement/mixPromotion/promotionDetail'),
                meta: { title: '编辑组合促销', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'promotionInstructions',
                name: 'promotionInstructions',
                component: () => import('@/views/PromotionManagement/promotionInstructions'),
                meta: { title: '促销说明管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangeParamSetting',
                name: 'priceChangeParamSetting',
                component: () => import('@/views/PromotionManagement/priceChange/paramSetting'),
                meta: { title: '变价参数配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangeParamQuickSetting',
                name: 'priceChangeParamQuickSetting',
                component: () => import('@/views/PromotionManagement/priceChange/paramQuickSetting'),
                meta: { title: '变价参数快速配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangeParamDetail',
                name: 'priceChangeParamDetail',
                component: () => import('@/views/PromotionManagement/priceChange/paramSettingDetail'),
                meta: { title: '变价参数配置详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangeListSetting',
                name: 'priceChangeListSetting',
                component: () => import('@/views/PromotionManagement/priceChange/listSetting'),
                meta: { title: '变价列表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionParamSetting',
                name: 'mixPromotionParamSetting',
                component: () => import('@/views/PromotionManagement/mixPromotion/paramSetting'),
                meta: { title: '组合促销参数设置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionParamQuickSetting',
                name: 'mixPromotionParamQuickSetting',
                component: () => import('@/views/PromotionManagement/mixPromotion/paramQuickSetting'),
                meta: { title: '组合参数快速配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionParamDetail',
                name: 'mixPromotionParamDetail',
                component: () => import('@/views/PromotionManagement/mixPromotion/paramSettingDetail'),
                meta: { title: '组合参数配置详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionListSetting',
                name: 'mixPromotionListSetting',
                component: () => import('@/views/PromotionManagement/mixPromotion/listSetting'),
                meta: { title: '组合列表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'myReports',
                name: 'myReports',
                component: () => import('@/views/configureReports/myLike'),
                meta: { title: '我的收藏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'table',
                name: 'table',
                component: () => import('@/views/configurationForm/table'),
                meta: { title: '配置表单静态用table', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/configurationForm/form'),
                meta: { title: '配置表单静态用form', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'formtab',
                name: 'formtab',
                component: () => import('@/views/configurationForm/tabs'),
                meta: { title: '配置表单静态用tab', icon: 'el-icon-user-solid', affix: false }
            },


            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: { title: '接口数据配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: { title: '接口数据配置详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: { title: '抛送数据配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: { title: '抛送数据配置详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: { title: '接口对外文档', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () => import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () => import('@/views/processDesign/processManage/addProcess/addProcessIndex'),
                meta: { title: '流程管理-新增工作', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () => import('@/views/processDesign/processManage/addProcess/addProcessMain.vue'),
                meta: { title: '流程管理-新增工作', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () => import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: { title: '流程管理-待办工作', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () => import('@/views/processDesign/processManage/myProcess/tab'),
                meta: { title: '流程管理-待办工作', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: { title: '打印模板设置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: { title: '打印模板设置新', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: { title: '打印模板列表新', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'developNeeds',
                name: 'developNeeds',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '开发需求', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onlinePlan',
                name: 'onlinePlan',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '上线计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('../views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false }//首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('../views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('../views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('../views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('../views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'informFile',
                name: 'informFile',
                component: () => import('../views/Inform/tab'),
                meta: { title: '通知文件', icon: 'el-icon-user-solid', affix: false }
            },
            // 瑞克斯新增路由
            {
                path: 'waybillList',
                name: 'waybillList',
                component: () => import('../views/dispatch/waybillList/list'),
                meta: { title: '运单管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waybillConfirm',
                name: 'waybillConfirm',
                component: () => import('../views/dispatch/waybillConfirm/waybillConfirm'),
                meta: { title: '运单确认', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'distributionPeportGoods',
                name: 'distributionPeportGoods',
                component: () => import('../views/dispatch/distributionPeportGoods/list'),
                meta: { title: '配货报货', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupDispatchList',
                name: 'pickupDispatchList',
                component: () => import('../views/dispatch/pickupDispatchList/list'),
                meta: { title: '提货派车', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupHandover',
                name: 'pickupHandover',
                component: () => import('../views/dispatch/pickupHandover/list'),
                meta: { title: '提货交接', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onwayList',
                name: 'onwayList',
                component: () => import('../views/onway/onwayList/list'),
                meta: { title: '在途管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'travelMap',
                name: 'travelMap',
                component: () => import('../views/onway/travel/map'),
                meta: { title: '轨迹追踪', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'stowageTransfer',
                name: 'stowageTransfer',
                component: () => import('../views/onway/stowageTransfer/list'),
                meta: { title: '配载中转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'deliverySignin',
                name: 'deliverySignin',
                component: () => import('../views/onway/deliverySignin/list'),
                meta: { title: '派送签收', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'abnormalList',
                name: 'abnormalList',
                component: () => import('../views/onway/abnormalList/list'),
                meta: { title: '异常管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPermission',
                name: 'userPermission',
                component: () => import('../views/userPermission/list'),
                meta: { title: '用户权限配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPITimelinessRate',
                name: 'KPITimelinessRate',
                component: () => import('../views/tmsDialog/KPITimelinessRate'),
                meta: { title: '到货及时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIOnTimeRate',
                name: 'KPIOnTimeRate',
                component: () => import('../views/tmsDialog/KPIOnTimeRate'),
                meta: { title: '提货准时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIEfficiency',
                name: 'KPIEfficiency',
                component: () => import('../views/tmsDialog/KPIEfficiency'),
                meta: { title: '提货效率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SCMKremainProcess',
                name: 'SCMKremainProcess',
                component: () => import('../views/processDesign/processManage/SCMKremainProcess/tab'),
                meta: { title: '生产模块-待办任务', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () => import('../views/processDesign/processManage/taskAgents/addProcessIndex'),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpurl',
                name: 'jumpurl',
                component: () => import('../views/jumpUrl'),
                meta: { title: '跳转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpTo/:menuid',
                name: 'jumpTo',
                component: () => import('../components/commonData/jumpTo'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAReport',
                name: 'BAReport',
                component: () => import('../views/BAReport/index'),
                meta: { title: '项目信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAFundReport',
                name: 'BAFundReport',
                component: () => import('../views/BAReport/BAFundReport'),
                meta: { title: '项目资金报表', icon: 'el-icon-user-solid', affix: false }
            },
            //配置大屏、BI
            {
                path: 'Settingscreen',
                name: 'Settingscreen',
                component: () => import('../views/setting/settingScreen'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingScreenN',
                name: 'settingScreenN',
                component: () => import('../views/screenSettingNew/tab'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toCsetting',
                name: 'toCsetting',
                component: () => import('@/views/setting/toCPlussetting'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toBIsetting',
                name: 'toBIsetting',
                component: () => import('../views/setting/toBIsetting'),
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReport',
                name: 'SettingBIReport',
                component: () => import('../views/BIConfiguration/index'),//BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReportN',
                name: 'SettingBIReportN',
                component: () => import('../views/BIConfiguration/indexNew'),//BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'show/:pageId',
                name: 'showScreen',
                component: () => import('../views/show/showScreen'),
                meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'chooseSwiperScreen',
                name: 'chooseSwiperScreen',
                component: () => import('../views/setting/chooseSwiperScreen'),
                meta: { title: '设置大屏组合轮播', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'carouselScreenList',
                name: 'carouselScreenList',
                component: () => import('../views/show/carouselScreenList'),
                meta: { title: '大屏组合轮播列表', icon: 'el-icon-user-solid', affix: false }
            },
            //交警项目
            {
                path: 'CoordinateTransform',
                name: 'CoordinateTransform',
                component: () => import('../views/CoordinateTransform'),
                meta: { title: '坐标转换', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'facilityChange/:menuid',
                name: 'facilityChange',
                component: () => import('../views/singlePage/facilityChange'),
                meta: { title: '设施/标线变更', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'facilityList',
                name: 'facilityList',
                component: () => import('../views/singlePage/facilityChange/facilityList'),
                meta: { title: '设施列表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('../views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'roadLine',
                name: 'roadLine',
                component: () => import('../views/DMap/roadLine'),
                meta: { title: '道路标线', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'areaLine',
                name: 'areaLine',
                component: () => import('../views/DMap/areaLine'),
                meta: { title: '区域维护', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'faclity',
                name: 'faclity',
                component: () => import('../views/faclity'),
                meta: { title: '套组详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'doubleMapCompare',
                name: 'doubleMapCompare',
                component: () => import('../components/dmap/doubleScreenCompare'),
                meta: { title: '双屏对比', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'View_line_change_history',
                name: 'View_line_change_history',
                component: () => import('../views/View_line_change_history'),
                meta: { title: '标线变更历史查看', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'customReport',
                name: 'customReport',
                component: () => import('../views/custom_report'),
                meta: { title: '定制报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'customStyle',
                name: 'customStyle',
                component: () => import('../views/customStyle'),
                meta: { title: '片区分布', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mapTotalData',
                name: 'mapTotalData',
                component: () => import('../views/DMap/mapTotalData'),
                meta: { title: '大屏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'DMap',
                name: 'DMap',
                component: () => import('../views/DMap/index'),
                meta: { title: '地图', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mapCompare',
                name: 'mapCompare',
                component: () => import('../views/DMap/mapCompare'),
                meta: { title: '地图对比', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('../views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'getSignal',
                name: 'getSignal',
                component: () => import('../views/DMap/getSignal'),
                meta: { title: '信号机坐标转换', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '2DReport',
                name: '2DReport',
                component: () => import('../views/2DReportInfo/groupIndex'),
                meta: { title: '设施总览', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('../views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            },
        ]
    },
    {
        path: '/show/:pageId',
        name: 'bigshowScreen',
        component: () => import('../views/show/showScreen'),
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Settingscreen',
        name: 'bigSettingscreen',
        component: () => import('../views/setting/settingScreen'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/SettingBIReport',
        name: 'bigSettingBIReport',
        component: () => import('../views/BIConfiguration/index'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bsettingScreenN',
        name: 'bsettingScreenN',
        component: () => import('../views/screenSettingNew/tab'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/autoLogin',
        name: 'autoLogin',
        component: () => import('../views/autoLogin'),
        meta: { title: '', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/BIautoLogin',
        name: 'BIautoLogin',
        component: () => import('../views/BIautoLogin'),
        meta: { title: '', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/carouselScreen/:id',
        name: 'carouselScreen',
        component: () => import('../views/show/carouselScreen'),
        meta: { title: '大屏组合轮播', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showBI/:pageId',
        name: 'bigshowBI',
        component: () => import('../views/BIConfiguration/showBI'),//BI配置
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('../views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('../views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/test'),

    },
    {
        path: '/echartspage',
        name: 'echartspage',
        component: () => import('../views/EchartsPage/echartspage'),
        meta: { title: 'echarts', icon: 'el-icon-user-solid', affix: false }
    }
]


export const routerDataPXXT = [
    {
        path: '/test',
        name: 'test',
        component: test
    },
    {
        path: '/WWLogin',
        name: 'WWLogin',
        component: () => import('@/views/WWLogin')
    },
    {
        path: '/',
        name: 'loginRoot',
        // component: () => import('@/views/pgLogin'),
        component: () => import('@/views/login')
    },
    {
        path: '/login',
        name: 'login',
        // component: () => import('@/views/pgLogin'),
        component: () => import('@/views/login')
    },
    {
        path: '/ssoLogin',
        name: 'ssoLogin',
        component: () => import('@/views/ssoLogin/ssoLogin')
    },
    //首页
    {
        path: '/jczxHomePage',
        name: 'jczxHomePage',
        component: () =>
            import('@/views/aloneViews/jczx/jczxHomePage/jczxHomePage'),
        meta: { title: '首页', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/jczxNotice',
        name: 'jczxNotice',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxNotice') //通知公告
    },
    {
        path: '/jczxNoticeDitails',
        name: 'jczxNoticeDitails',
        component: () =>
            import('@/views/aloneViews/jczx/jczxHomePage/jczxNoticeDitails') //通知公告
    },
    //   {
    //     path: '/jczxWorkStatus',
    //     name: 'jczxWorkStatus',
    //     component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxWorkStatus') //工作动态
    //   },
    {
        path: '/jczxWork',
        name: 'jczxWork',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/jczxWork') //工作动态
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aloneViews/jczx/jczxHomePage/aboutUs.vue') //联系我们
    },
    {
        path: '/HomePage',
        name: 'HomePage',
        component: () => import('@/views/HomePage/index')
    },
    {
        path: '/AnswerPaper',
        name: 'AnswerPaper',
        component: () => import('@/views/AnswerPaper.vue')
    },
    {
        path: '/inputFingerprint',
        name: 'inputFingerprint',
        component: () => import('@/views/inputFingerprint')
    },
    {
        path: '/flipBook',
        name: 'flipBook',
        component: () => import('@/views/threeD/threeD-train/flipBook-train')
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: markRaw(() => import('@/views/home')),
        children: [
            {
                path: 'curriculumsTable',
                name: 'curriculumsTable',
                component: () => import('@/views/trainingSystem/curriculumsTable.vue'),
                meta: { title: '课程列表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'courseLearning',
                name: 'courseLearning',
                component: () => import('@/views/trainingSystem/courseLearning.vue'),
                meta: { title: '课程学习', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waitExamLists',
                name: 'waitExamLists',
                component: () => import('@/views/trainingSystem/waitExamLists.vue'),
                meta: { title: '待考试列表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'examOnlines',
                name: 'examOnlines',
                component: () => import('@/views/trainingSystem/examOnlines.vue'),
                meta: { title: '在线考试', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'examRecords',
                name: 'examRecords',
                component: () => import('@/views/trainingSystem/examRecords.vue'),
                meta: { title: '批阅列表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '/redirect',
                component: () => import('@/views/redirect/index'),
                hidden: true,
                children: [
                    {
                        path: '/redirect/:path(.*)'
                    }
                ]
            },
            {
                path: 'dragHome',
                name: 'dragHome',
                component: () => import('@/views/dragHome.vue'),
                meta: { title: '推拽组件', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'floor',
                name: 'floor',
                component: () => import('@/views/threeD/floor'),
                meta: { title: '3D楼层', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'floorIframe',
                name: 'floorIframe',
                component: () => import('@/views/threeD/floorIframe'),
                meta: {
                    title: '3D楼层平面版',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/dashboardhome'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'ssoIframe', //单点登录
                name: 'ssoIframe',
                component: () => import('@/views/ssoLogin/ssoIframe')
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'perExamine',
                name: 'perExamine',
                component: () => import('@/views/singlePage/perExamine'),
                meta: { title: '注册审核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: {
                    title: '任务管理平台',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'userIndex',
                name: 'UserIndex',
                component: () => import('@/views/UserIndex'),
                meta: { title: '人员信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userDetail',
                name: 'UserDetail',
                component: () => import('@/views/UserDetail'),
                meta: { title: '人员详情', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'management',
                name: 'Management',
                component: () => import('@/views/FileManagement/management'),
                meta: {
                    title: '管理架构维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'postMaintenance',
                name: 'PostMaintenance',
                component: () => import('@/views/FileManagement/postMaintenance'),
                meta: {
                    title: '岗位档案维护',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // {
            //   path: 'withdrawal',
            //   name: 'Withdrawal',
            //   component: ()=>import('@/views/Withdrawal/withdrawal') ,
            //   meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'Withdrawalorder',
                name: 'Withdrawalorder',
                component: () => import('@/views/Withdrawal/Withdrawalorder'),
                meta: { title: '门店退总仓', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'withdrawalDetails',
            //   name: 'withdrawalDetails',
            //   component: ()=>import('@/views/Withdrawal/withdrawalDetails') ,
            //   meta: { title: '商品明细', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'withdrawalOrderDetails',
                name: 'withdrawalOrderDetails',
                component: () => import('@/views/Withdrawal/withdrawalOrderDetails'),
                meta: {
                    title: '门店退仓商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            // {
            //   path: 'scancode',
            //   name: 'scancode',
            //   component: ()=>import('@/views/Withdrawal/scancode') ,
            //   meta: { title: '扫码', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'scancodeOrder',
                name: 'scancodeOrder',
                component: () => import('@/views/Withdrawal/scancodeOrder'),
                meta: {
                    title: '门店退仓扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersIn',
                name: 'transfersIn',
                component: () => import('@/views/Transfers/transfersIn'),
                meta: {
                    title: '门店调拨-转入',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersInDetail',
                name: 'transfersInDetail',
                component: () => import('@/views/Transfers/transfersInDetail'),
                meta: {
                    title: '门店调拨转入明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'transfersOut',
                name: 'transfersOut',
                component: () => import('@/views/Transfers/transfersIndex'),
                meta: {
                    title: '门店调拨-转出',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'transfersOutDetail',
                name: 'transfersOutDetail',
                component: () => import('@/views/Transfers/transfersDetail'),
                meta: {
                    title: '门店调拨转出明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancode',
                name: 'tranScancode',
                component: () => import('@/views/Transfers/scancode'),
                meta: {
                    title: '门店转出扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'tranScancodeIn',
                name: 'tranScancodeIn',
                component: () => import('@/views/Transfers/scancodeIn'),
                meta: {
                    title: '门店转入扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'receiving',
                name: 'receiving',
                component: () => import('@/views/Receiving/receiving'),
                meta: { title: '收货复核', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'receivingDetails',
                name: 'receivingDetails',
                component: () => import('@/views/Receiving/receivingDetails'),
                meta: {
                    title: '收货复核商品明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'recevescancode',
                name: 'recevescancode',
                component: () => import('@/views/Receiving/recevescancode'),
                meta: {
                    title: '收货复核扫码',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'OutboundReturnReport',
                name: 'OutboundReturnReport',
                component: () => import('@/views/storeReport/OutboundReturnReport'),
                meta: {
                    title: '门店出库退货查询',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: {
                    title: '配置报表展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: {
                    title: '配置表单展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: {
                    title: '配置表单分类展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'InvoicingReport',
                name: 'InvoicingReport',
                component: () => import('@/views/storeReport/InvoicingReport'),
                meta: {
                    title: '仓店任意日进销存报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'posindex',
                name: 'posindex',
                component: () => import('@/views/Pos/posindex'),
                meta: { title: '在线POS', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryPlan',
                name: 'StoreInventoryPlan',
                component: () => import('@/views/StoreInventory/StoreInventoryPlan'),
                meta: {
                    title: '门店盘点计划',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryPlanDetail',
                name: 'StoreInventoryPlanDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryPlanDetail'),
                meta: {
                    title: '门店盘点计划明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreInventoryList',
                name: 'StoreInventoryList',
                component: () => import('@/views/StoreInventory/StoreInventoryList'),
                meta: { title: '门店盘点单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreInventoryListDetail',
                name: 'StoreInventoryListDetail',
                component: () =>
                    import('@/views/StoreInventory/StoreInventoryListDetail'),
                meta: {
                    title: '门店盘点单详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'StoreAdjustmentList',
                name: 'StoreAdjustmentList',
                component: () => import('@/views/StoreAdjustment/StoreAdjustmentList'),
                meta: { title: '门店调整单', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'StoreAdjustmentDetail',
                name: 'StoreAdjustmentDetail',
                component: () =>
                    import('@/views/StoreAdjustment/StoreAdjustmentDetail'),
                meta: {
                    title: '门店调整单明细',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'carouselScreen/:id',
                name: 'carouselScreen',
                component: () => import('@/views/show/carouselScreen'),
                meta: {
                    title: '大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangePromotion',
                name: 'priceChangePromotion',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotion'),
                meta: { title: '变价促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'priceChangePromotionDetail',
                name: 'priceChangePromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/promotionDetail'),
                meta: {
                    title: '编辑变价促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeBatchModify',
                name: 'priceChangeBatchModify',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/batchModify'),
                meta: {
                    title: '变价促销批量修改',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionPromotion',
                name: 'mixPromotionPromotion',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotion'),
                meta: { title: '组合促销', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'mixPromotionPromotionDetail',
                name: 'mixPromotionPromotionDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/promotionDetail'),
                meta: {
                    title: '编辑组合促销',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'promotionInstructions',
                name: 'promotionInstructions',
                component: () =>
                    import('@/views/PromotionManagement/promotionInstructions'),
                meta: {
                    title: '促销说明管理',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamSetting',
                name: 'priceChangeParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSetting'),
                meta: {
                    title: '变价参数配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamQuickSetting',
                name: 'priceChangeParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramQuickSetting'),
                meta: {
                    title: '变价参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeParamDetail',
                name: 'priceChangeParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/paramSettingDetail'),
                meta: {
                    title: '变价参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'priceChangeListSetting',
                name: 'priceChangeListSetting',
                component: () =>
                    import('@/views/PromotionManagement/priceChange/listSetting'),
                meta: {
                    title: '变价列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamSetting',
                name: 'mixPromotionParamSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSetting'),
                meta: {
                    title: '组合促销参数设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamQuickSetting',
                name: 'mixPromotionParamQuickSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramQuickSetting'),
                meta: {
                    title: '组合参数快速配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionParamDetail',
                name: 'mixPromotionParamDetail',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/paramSettingDetail'),
                meta: {
                    title: '组合参数配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'mixPromotionListSetting',
                name: 'mixPromotionListSetting',
                component: () =>
                    import('@/views/PromotionManagement/mixPromotion/listSetting'),
                meta: {
                    title: '组合列表配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myReports',
                name: 'myReports',
                component: () => import('@/views/configureReports/myLike'),
                meta: { title: '我的收藏', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'table',
                name: 'table',
                component: () => import('@/views/configurationForm/table'),
                meta: {
                    title: '配置表单静态用table',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/configurationForm/form'),
                meta: {
                    title: '配置表单静态用form',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formtab',
                name: 'formtab',
                component: () => import('@/views/configurationForm/tabs'),
                meta: {
                    title: '配置表单静态用tab',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: {
                    title: '接口数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: {
                    title: '接口数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: {
                    title: '抛送数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: {
                    title: '抛送数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: {
                    title: '接口对外文档',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'processControl',
            //   name: 'processControl',
            //   component: () => import('@/views/processDesign/processControl/index'),
            //   meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            // },

            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () =>
                    import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessIndex'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessMain.vue'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () =>
                    import('@/views/processDesign/processManage/myProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: {
                    title: '打印模板设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: {
                    title: '打印模板设置新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: {
                    title: '打印模板列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: {
                    title: '打印模板列表新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'developNeeds',
                name: 'developNeeds',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '开发需求', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onlinePlan',
                name: 'onlinePlan',
                component: () => import('@/views/dashboard/components/developNeeds'),
                meta: { title: '上线计划', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('@/views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false } //首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('@/views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('@/views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('@/views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('@/views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'informFile',
                name: 'informFile',
                component: () => import('@/views/Inform/tab'),
                meta: { title: '通知文件', icon: 'el-icon-user-solid', affix: false }
            },
            // 瑞克斯新增路由
            {
                path: 'waybillList',
                name: 'waybillList',
                component: () => import('@/views/dispatch/waybillList/list'),
                meta: { title: '运单管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'waybillConfirm',
                name: 'waybillConfirm',
                component: () =>
                    import('@/views/dispatch/waybillConfirm/waybillConfirm'),
                meta: { title: '运单确认', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'distributionPeportGoods',
                name: 'distributionPeportGoods',
                component: () =>
                    import('@/views/dispatch/distributionPeportGoods/list'),
                meta: { title: '配货报货', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupDispatchList',
                name: 'pickupDispatchList',
                component: () => import('@/views/dispatch/pickupDispatchList/list'),
                meta: { title: '提货派车', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'pickupHandover',
                name: 'pickupHandover',
                component: () => import('@/views/dispatch/pickupHandover/list'),
                meta: { title: '提货交接', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'onwayList',
                name: 'onwayList',
                component: () => import('@/views/onway/onwayList/list'),
                meta: { title: '在途管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'travelMap',
                name: 'travelMap',
                component: () => import('@/views/onway/travel/map'),
                meta: { title: '轨迹追踪', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'stowageTransfer',
                name: 'stowageTransfer',
                component: () => import('@/views/onway/stowageTransfer/list'),
                meta: { title: '配载中转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'deliverySignin',
                name: 'deliverySignin',
                component: () => import('@/views/onway/deliverySignin/list'),
                meta: { title: '派送签收', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'abnormalList',
                name: 'abnormalList',
                component: () => import('@/views/onway/abnormalList/list'),
                meta: { title: '异常管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPermission',
                name: 'userPermission',
                component: () => import('@/views/userPermission/list'),
                meta: {
                    title: '用户权限配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'KPITimelinessRate',
                name: 'KPITimelinessRate',
                component: () => import('@/views/tmsDialog/KPITimelinessRate'),
                meta: { title: '到货及时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIOnTimeRate',
                name: 'KPIOnTimeRate',
                component: () => import('@/views/tmsDialog/KPIOnTimeRate'),
                meta: { title: '提货准时率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'KPIEfficiency',
                name: 'KPIEfficiency',
                component: () => import('@/views/tmsDialog/KPIEfficiency'),
                meta: { title: '提货效率', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SCMKremainProcess',
                name: 'SCMKremainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/SCMKremainProcess/tab'),
                meta: {
                    title: '生产模块-待办任务',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/taskAgents/addProcessIndex'
                    ),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'sampleDetail',
            //   name: 'sampleDetail',
            //   component: () => import('@/views/sampleDetail/index'),
            //   meta: { title: '戎美样衣详情', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'jumpurl',
                name: 'jumpurl',
                component: () => import('@/views/jumpUrl'),
                meta: { title: '跳转', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'jumpTo/:menuid',
                name: 'jumpTo',
                component: () => import('@/components/commonData/jumpTo'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAReport',
                name: 'BAReport',
                component: () => import('@/views/BAReport/index'),
                meta: { title: '项目信息', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'BAFundReport',
                name: 'BAFundReport',
                component: () => import('@/views/BAReport/BAFundReport'),
                meta: {
                    title: '项目资金报表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            //配置大屏、BI
            {
                path: 'Settingscreen',
                name: 'Settingscreen',
                component: () => import('@/views/setting/settingScreen'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingScreenN',
                name: 'settingScreenN',
                component: () => import('@/views/screenSettingNew/tab'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toCsetting',
                name: 'toCsetting',
                component: () => import('@/views/setting/toCPlussetting.vue'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //     path: 'toCsetting',
            //     name: 'toCsetting',
            //     component: toCSetting,
            //     meta: {title: '大屏配置', icon: 'el-icon-user-solid', affix: false},
            // },
            {
                path: 'toBIsetting',
                name: 'toBIsetting',
                component: () => import('@/views/setting/toBIsetting'),
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReport',
                name: 'SettingBIReport',
                component: () => import('@/views/BIConfiguration/index'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReportN',
                name: 'SettingBIReportN',
                component: () => import('@/views/BIConfiguration/indexNew'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'show/:pageId',
                name: 'showScreen',
                component: () => import('@/views/show/showScreen'),
                meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'chooseSwiperScreen',
                name: 'chooseSwiperScreen',
                component: () => import('@/views/setting/chooseSwiperScreen'),
                meta: {
                    title: '设置大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'carouselScreenList',
                name: 'carouselScreenList',
                component: () => import('@/views/show/carouselScreenList'),
                meta: {
                    title: '大屏组合轮播列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            //交警项目
            // {
            //   path: 'facilityChange/:menuid',
            //   name: 'facilityChange',
            //   component: () => import('@/views/singlePage/facilityChange'),
            //   meta: { title: '设施/标线变更', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'facilityList',
            //   name: 'facilityList',
            //   component: () => import('@/views/singlePage/facilityChange/facilityList'),
            //   meta: { title: '设施列表', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('@/views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            // {
            //   path: 'demo',
            //   name: 'demo',
            //   component: ()=>import('@/views/demo'),
            //   meta: { title: '地图首页', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'roadLine',
            //   name: 'roadLine',
            //   component: ()=>import('@/views/DMap/roadLine'),
            //   meta: { title: '道路标线', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'areaLine',
            //   name: 'areaLine',
            //   component: ()=>import('@/views/DMap/areaLine'),
            //   meta: { title: '区域维护', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'faclity',
            //   name: 'faclity',
            //   component: ()=>import('@/views/faclity'),
            //   meta: { title: '套组详情', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'doubleMapCompare',
            //   name: 'doubleMapCompare',
            //   component: () => import('@/components/dmap/doubleScreenCompare'),
            //   meta: { title: '双屏对比', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'View_line_change_history',
            //   name: 'View_line_change_history',
            //   component: () => import('@/views/View_line_change_history'),
            //   meta: { title: '标线变更历史查看', icon: 'el-icon-user-solid', affix: false }
            // },
            //  {
            //   path: 'customReport',
            //   name: 'customReport',
            //   component: () => import('@/views/custom_report'),
            //   meta: { title: '定制报表', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'customStyle',
            //   name: 'customStyle',
            //   component: () => import('@/views/customStyle'),
            //   meta: { title: '片区分布', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'mapTotalData',
            //   name: 'mapTotalData',
            //   component: () => import('@/views/DMap/mapTotalData'),
            //   meta: { title: '大屏', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'DMap',
            //   name: 'DMap',
            //   component: () => import('@/views/DMap/index'),
            //   meta: { title: '地图', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: 'mapCompare',
            //   name: 'mapCompare',
            //   component: () => import('@/views/DMap/mapCompare'),
            //   meta: { title: '地图对比', icon: 'el-icon-user-solid', affix: false }
            // },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('@/views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },

            // {
            //   path: 'getSignal',
            //   name: 'getSignal',
            //   component: () => import('@/views/DMap/getSignal'),
            //   meta: { title: '信号机坐标转换', icon: 'el-icon-user-solid', affix: false }
            // },
            // {
            //   path: '2DReport',
            //   name: '2DReport',
            //   component: () => import('@/views/2DReportInfo/groupIndex'),
            //   meta: { title: '设施总览', icon: 'el-icon-user-solid', affix: false }
            // },
            //树型菜单
            {
                path: 'treeMenu',
                name: 'treeMenu',
                //component: () => import('@/views/login')
                component: resolve =>
                    require(['@/views/configurationForm/treeMenu'], resolve)
            },
            //调度中心页面
            {
                path: 'dispatchingCenter',
                name: 'dispatchingCenter',
                component: () =>
                    import('@/views/aloneViews/pg/dispatchingCenter/index'),
                meta: { title: '调度中心', icon: 'el-icon-user-solid', affix: false }
            },
            // //部门->驻点 跳转到排班页面
            // {
            //     path: 'chooseJump',
            //     name: 'chooseJump',
            //     component: () => import('@/views/aloneViews/pg/chooseJump/chooseJump'),
            //     meta: {title: '部门->驻点', icon: 'el-icon-user-solid', affix: false}
            // },

            // {
            //     path: 'arrangeGrade',
            //     name: 'arrangeGrade',
            //     component: () => import('@/views/aloneViews/pg/scheduling/arrangeGrade'),
            //     // component: arrangeGrade,
            //     meta: {title: '排班', icon: 'el-icon-user-solid', affix: false}
            // },
            // //个人排班
            // {
            //     path: 'perScheduling',
            //     name: 'perScheduling',
            //     component: () => import('@/views/aloneViews/pg/scheduling/perScheduling'),
            //     meta: {title: '个人排班', icon: 'el-icon-user-solid', affix: false}
            // },
            //人员画像
            {
                path: 'perPortrait',
                name: 'perPortrait',
                component: () => import('@/views/aloneViews/pg/perPortrait/index'),
                meta: { title: '人员画像', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dialogSearchJL',
                name: 'dialogSearchJL',
                component: () => import('@/views/aloneViews/jczx/dialogSearchJL'),
                meta: { title: '弹窗', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'appointmentIndex',
                name: 'appointmentIndex',
                component: () => import('@/views/aloneViews/jczx/appointmentIndex'),
                meta: { title: '计量所首页', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'qualityInspectionInstitute',
                name: 'qualityInspectionInstitute',
                component: () =>
                    import('@/views/aloneViews/jczx/qualityInspectionInstitute'),
                meta: { title: '质检所首页', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'inputFingerprint',
                name: 'inputFingerprint',
                component: () => import('@/views/inputFingerprint'),
                meta: { title: '录入指纹', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            }
        ]
    },
    {
        path: '/show/:pageId',
        name: 'bigshowScreen',
        component: () => import('@/views/show/showScreen'),
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bsettingScreenN',
        name: 'bsettingScreenN',
        component: () => import('@/views/screenSettingNew/tab'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showBI/:pageId',
        name: 'bigshowBI',
        component: () => import('@/views/BIConfiguration/showBI'), //BI配置
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('@/views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('@/views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bprocess',
        name: 'bprocess',
        component: () =>
            import('@/views/processDesign/processControl/bigprocessmodify'),
        meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/echartspage',
        name: 'echartspage',
        component: () => import('@/views/EchartsPage/echartspage'),
        meta: { title: 'echarts', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/caseguide',
        name: 'caseguide',
        component: () => import('@/views/PropertyManagesystem/caseGuide'),
        meta: {
            title: '常熟市涉案财物管理中心',
            icon: 'el-icon-user-solid',
            affix: false
        }
    },
    {
        path: '/uploadFace',
        name: 'uploadFace',
        component: () => import('@/views/customMade/uploadFace'),
        meta: { title: '人脸上传', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/BIConfiguration',
        name: 'BIConfiguration',
        component: () => import('@/views/BIConfiguration/BIConfiguration'),
        meta: { title: 'BI参数配置', icon: 'el-icon-user-solid', affix: false }
    },

    //戎美售后
    {
        path: '/receiptVoucher',
        name: 'receiptVoucher',
        component: () => import('@/views/RMAfterSaleSys/receiptVoucher'),
        meta: { title: '入库制单', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/ExpressUnpacking',
        name: 'ExpressUnpacking',
        component: () => import('@/views/RMAfterSaleSys/ExpressUnpacking'),
        meta: { title: '快递拆包', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/OutorderSearch',
        name: 'OutorderSearch',
        component: () => import('@/views/RMAfterSaleSys/OutorderSearch'),
        meta: { title: '出库单查询', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/AddOut',
        name: 'AddOut',
        component: () => import('@/views/RMAfterSaleSys/AddOut'),
        meta: { title: '出库单新增', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Refund',
        name: 'Refund',
        component: () => import('@/views/RMAfterSaleSys/Refund'),
        meta: { title: '退款', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Maintenance',
        name: 'Maintenance',
        component: () => import('@/views/RMAfterSaleSys/Maintenance'),
        meta: { title: '维修单', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/QualityTest',
        name: 'QualityTest',
        component: () => import('@/views/RMAfterSaleSys/QualityTest'),
        meta: { title: '质检', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/Anomaly',
        name: 'Anomaly',
        component: () => import('@/views/RMAfterSaleSys/Anomaly'),
        meta: { title: '异常处理', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/RefundAnomaly',
        name: 'RefundAnomaly',
        component: () => import('@/views/RMAfterSaleSys/RefundAnomaly'),
        meta: { title: '退款异常处理', icon: 'el-icon-user-solid', affix: false }
    }
]

// 常熟税务局
export const routerDataSWJ = [
    {
        path: '/',
        name: 'loginRoot',
        component: () => import('@/views/login')
    },
    {
        path: '/login',
        name: 'login',
        // component: () => import('@/views/pgLogin'),
        component: () => import('@/views/login')
    },
    {
        path: '/ssoLogin',
        name: 'ssoLogin',
        component: () => import('@/views/ssoLogin/ssoLogin')
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/dashboard',
        component: markRaw(() => import('@/views/home')),
        children: [
            {
                path: '/redirect',
                component: () => import('@/views/redirect/index'),
                hidden: true,
                children: [
                    {
                        path: '/redirect/:path(.*)'
                    }
                ]
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/dashboardhome'),
                meta: { title: '首页', icon: 'el-icon-user-solid', affix: true }
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('@/views/roleSearch'),
                meta: { title: '角色', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'menuPower',
                name: 'MenuPower',
                component: () => import('@/views/menuPermissions'),
                meta: { title: '权限', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'userPassword',
                name: 'UserPassword',
                component: () => import('@/views/userPassword'),
                meta: { title: '用户密码', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'xxl',
                name: 'xxl',
                component: () => import('@/views/xxl'),
                meta: {
                    title: '任务管理平台',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationReport/:menuid',
                name: 'configurationReport',
                component: () => import('@/views/configurationReport/index'),
                meta: {
                    title: '配置报表展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationForm/:menuid',
                name: 'configurationForm',
                component: () => import('@/views/configurationForm/index'),
                meta: {
                    title: '配置表单展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'configurationFormGroup/:menuid',
                name: 'configurationFormGroup',
                component: () => import('@/views/configurationForm/groupIndex'),
                meta: {
                    title: '配置表单分类展示',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'settingComponents',
                name: 'settingComponents',
                component: () => import('@/views/setting/settingComponents'),
                meta: { title: '组件编辑', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'configureReports',
                name: 'configureReports',
                component: () => import('@/views/configureReports/configureReports'),
                meta: { title: '配置报表', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'carouselScreen/:id',
                name: 'carouselScreen',
                component: () => import('@/views/show/carouselScreen'),
                meta: {
                    title: '大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            // 测试
            {
                path: 'interfaceList',
                name: 'interfaceList',
                component: () => import('@/views/dataConfig/interfaceData/list'),
                meta: {
                    title: '接口数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceDetail',
                name: 'interfaceDetail',
                component: () => import('@/views/dataConfig/interfaceData/detail'),
                meta: {
                    title: '接口数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwList',
                name: 'throwList',
                component: () => import('@/views/dataConfig/throwData/list'),
                meta: {
                    title: '抛送数据配置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'throwDetail',
                name: 'throwDetail',
                component: () => import('@/views/dataConfig/throwData/detail'),
                meta: {
                    title: '抛送数据配置详情',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'interfaceFile',
                name: 'interfaceFile',
                component: () => import('@/views/dataConfig/interfaceFile'),
                meta: {
                    title: '接口对外文档',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'formControl',
                name: 'formControl',
                component: () => import('@/views/processDesign/formControl/index'),
                meta: { title: '表单设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'typeControl',
                name: 'typeControl',
                component: () => import('@/views/processDesign/typeControl/index'),
                meta: { title: '分类设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'rightMenu/:menuid',
                name: 'rightMenu',
                component: () => import('@/components/commonData/rightMenuIndex'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'processControl',
                name: 'processControl',
                component: () =>
                    import('@/views/processDesign/processControl/processTable'),
                meta: { title: '流程设计', icon: 'el-icon-user-solid', affix: false }
            },

            {
                path: 'addProcess/:wfid',
                name: 'addProcess',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessIndex'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'addProcessMain',
                name: 'addProcessMain',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/addProcess/addProcessMain.vue'
                    ),
                meta: {
                    title: '流程管理-新增工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'remainProcess',
                name: 'remainProcess',
                component: () =>
                    import('@/views/processDesign/processManage/remainProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'myProcess',
                name: 'myProcess',
                component: () =>
                    import('@/views/processDesign/processManage/myProcess/tab'),
                meta: {
                    title: '流程管理-待办工作',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },

            {
                path: 'printSet/:moduleid',
                name: 'printSet',
                component: () => import('@/views/printSet/index'),
                meta: {
                    title: '打印模板设置',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printSetn/:moduleid',
                name: 'printSetn',
                component: () => import('@/views/printSet/indexNew'),
                meta: {
                    title: '打印模板设置新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndex',
                name: 'printIndex',
                component: () => import('@/views/printSet/tabs'),
                meta: {
                    title: '打印模板列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'printIndexn',
                name: 'printIndexn',
                component: () => import('@/views/printSet/tabsNew'),
                meta: {
                    title: '打印模板列表新',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'proLog',
                name: 'proLog',
                component: () => import('@/views/dashboard/components/proLog'),
                meta: { title: '个人日程', icon: 'el-icon-user-solid', affix: false } //首页表单
            },

            {
                path: 'formSetting',
                name: 'formSetting',
                component: () => import('@/views/formSetting'),
                meta: { title: '表单配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSetting',
                name: 'reportSetting',
                component: () => import('@/views/reportSetting'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'reportSettingN',
                name: 'reportSettingN',
                component: () => import('@/views/reportSetting/indexNew'),
                meta: { title: '报表配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'dicIndex',
                name: 'dicIndex',
                component: () => import('@/views/dic'),
                meta: { title: '字典配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'taskAgents/:wfid',
                name: 'taskAgents',
                component: () =>
                    import(
                        '@/views/processDesign/processManage/taskAgents/addProcessIndex'
                    ),
                meta: { title: '代办任务', icon: 'el-icon-user-solid', affix: false }
            },
            //配置大屏、BI
            {
                path: 'Settingscreen',
                name: 'Settingscreen',
                component: () => import('@/views/setting/settingScreen'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'settingScreenN',
                name: 'settingScreenN',
                component: () => import('@/views/screenSettingNew/tab'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toCsetting',
                name: 'toCsetting',
                component: () => import('@/views/setting/toCPlussetting.vue'),
                meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'toBIsetting',
                name: 'toBIsetting',
                component: () => import('@/views/setting/toBIsetting'),
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReport',
                name: 'SettingBIReport',
                component: () => import('@/views/BIConfiguration/index'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'SettingBIReportN',
                name: 'SettingBIReportN',
                component: () => import('@/views/BIConfiguration/indexNew'), //BI配置
                meta: { title: 'BI配置', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'show/:pageId',
                name: 'showScreen',
                component: () => import('@/views/show/showScreen'),
                meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'chooseSwiperScreen',
                name: 'chooseSwiperScreen',
                component: () => import('@/views/setting/chooseSwiperScreen'),
                meta: {
                    title: '设置大屏组合轮播',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'carouselScreenList',
                name: 'carouselScreenList',
                component: () => import('@/views/show/carouselScreenList'),
                meta: {
                    title: '大屏组合轮播列表',
                    icon: 'el-icon-user-solid',
                    affix: false
                }
            },
            {
                path: 'dairyLog',
                name: 'dairyLog',
                component: () => import('@/views/singlePage/dairyLog'),
                meta: { title: '日志管理', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: 'employee/:menuid',
                name: 'employee',
                component: () => import('@/views/configurationForm/employeeInfoIndex'),
                meta: { title: '员工信息', icon: 'el-icon-user-solid', affix: false }
            },
            //树型菜单
            {
                path: 'treeMenu',
                name: 'treeMenu',
                //component: () => import('@/views/login')
                component: resolve =>
                    require(['@/views/configurationForm/treeMenu'], resolve)
            },
            //调度中心页面
            {
                path: 'dispatchingCenter',
                name: 'dispatchingCenter',
                component: () =>
                    import('@/views/aloneViews/pg/dispatchingCenter/index'),
                meta: { title: '调度中心', icon: 'el-icon-user-solid', affix: false }
            },
            {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/404.vue'), //404
                meta: { title: '404', icon: 'el-icon-user-solid', affix: false }
            }
        ]
    },
    {
        path: '/show/:pageId',
        name: 'bigshowScreen',
        component: () => import('@/views/show/showScreen'),
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/bsettingScreenN',
        name: 'bsettingScreenN',
        component: () => import('@/views/screenSettingNew/tab'),
        meta: { title: '大屏配置', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/showBI/:pageId',
        name: 'bigshowBI',
        component: () => import('@/views/BIConfiguration/showBI'), //BI配置
        meta: { title: '大屏报表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printIndexB',
        name: 'printIndexB',
        component: () => import('@/views/printSet/tabs'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        path: '/printShow',
        name: 'printShow',
        component: () => import('@/views/printIndex/index'),
        meta: { title: '打印模板列表', icon: 'el-icon-user-solid', affix: false }
    },
    {
        //7台自助打印一体机高度不一致
        name: 'screenBarblack',
        path: '/screenBarblack',
        component: () => import('../views/screenBar/screenBarblack.vue')
    },
    {
        //7台自助打印一体机高度一致
        name: 'screenBar',
        path: '/screenBar',
        component: () => import('../views/screenBar/screenBar.vue')
    },
    {
        //自助办税区 3台自助一体机处高度不一致
        name: 'screenBar2black',
        path: '/screenBar2black',
        component: () => import('../views/screenBar/screenBar2black.vue')
    },
    {
        //自助办税区 3台自助一体机处高度一致
        name: 'screenBar2',
        path: '/screenBar2',
        component: () => import('../views/screenBar/screenBar2.vue')
    },
    {
        //智能柜台区 5间隔间高度不一致
        name: 'screenBar3black',
        path: '/screenBar3black',
        component: () => import('../views/screenBar/screenBar3black.vue')
    },
    {
        //智能柜台区 5间隔间高度一致
        name: 'screenBar3',
        path: '/screenBar3',
        component: () => import('../views/screenBar/screenBar3.vue')
    },
    {
        //条形屏设置
        name: 'screenBarSetting',
        path: '/screenBarSetting',
        component: () => import('../views/screenBarSetting/screenBarSetting.vue')
    },
]